import React, { useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import {
  HomeOutlined,
  UsergroupAddOutlined,
  InboxOutlined,
  HolderOutlined,
  SettingOutlined,
  CarOutlined,
  DropboxOutlined,
  LogoutOutlined,
  DownOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import { APP_URL } from "../../../URL";

const MenuList = ({ darkTheme }) => {
  const primaryColor = "#223457";

  const handleLogout = () => {
    axios
      .post(APP_URL + "logout")
      .then((response) => {
        console.log(response.data.message);
        window.location.href = "/login";
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion :", error);
      });
  };

  const [role, setRole] = useState("");
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(atob(payloadBase64));
        setRole(payloadJSON.role);
      } catch (error) {
        console.log("Erreur de décodage du token JWT", error);
      }
    }
  }, [token]);

  const bookingMenu = (
    <Menu>
      <Menu.Item key="listBooking">
        <Link to="/ListBooking" className="text-decoration-none">
          List Booking
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <Menu
      theme={darkTheme ? "dark" : "light"}
      className="menu-bar"
      style={{
        backgroundColor: darkTheme ? primaryColor : "#fff",
        color: darkTheme ? "#fff" : "#000",
      }}
    >
      <Menu.Item key="home" icon={<HomeOutlined />}>
        <Link to="/dashboard" className="text-decoration-none">
          Dashboard
        </Link>
      </Menu.Item>
      {role === "admin" && (
        <Menu.Item key="client" icon={<DropboxOutlined />}>
          <Link to="/Listclient" className="text-decoration-none">
            Client
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="palette" icon={<DropboxOutlined />}>
        <Link to="/palette" className="text-decoration-none">
          Palette
        </Link>
      </Menu.Item>
      <Menu.Item key="vehicule" icon={<CarOutlined />}>
        <Link to="/vehicule" className="text-decoration-none">
          Vehicule
        </Link>
      </Menu.Item>
      {role === "admin" && (
        <Dropdown overlay={bookingMenu}>
          <Menu.Item key="reservation" icon={<InboxOutlined />}>
            Réservation <DownOutlined />
          </Menu.Item>
        </Dropdown>
      )}
      <Menu.Item key="booking" icon={<InboxOutlined />}>
        <Link to="/booking" className="text-decoration-none">
          Booking
        </Link>
      </Menu.Item>
      <Menu.Item key="shipping" icon={<HolderOutlined />}>
        <Link to="/shipping" className="text-decoration-none">
          Shipping instructions
        </Link>
      </Menu.Item>
      <Menu.Item key="shipping" icon={<FilePdfOutlined />}>
        <Link to="/draft" className="text-decoration-none">
          Draft
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="settings" icon={<SettingOutlined />}>
        Settings
      </Menu.Item> */}
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );
};

export default MenuList;
