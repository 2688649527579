import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { APP_URL } from "../../../URL";

const ListClient = () => {
  const [clients, setClients] = useState([]);
  const [editingClient, setEditingClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const token = localStorage.getItem("token");
  const [idCLi, setIDcli] = useState("");

  //récupérer le token
  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(window.atob(payloadBase64));
        setIDcli(payloadJSON.clientId);
        console.log(idCLi);
      } catch (error) {
        console.log(error.message);
      }
    }
  }, [token]);

  useEffect(() => {
    axios
      .get(APP_URL + "client", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const handleEdit = (client) => {
    setEditingClient(client);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditingClient(null);
    setShowModal(false);
  };

  const handleUpdate = () => {
    if (!editingClient || !editingClient.id_cli) {
      console.error("L'objet editingClient ou son ID est incorrect.");
      return;
    }

    console.log("ID du client à mettre à jour :", editingClient.id_cli);
    axios
      .put(`${APP_URL}client/${editingClient.id_cli}`, editingClient, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Client mis à jour avec succès");
        setShowModal(false);
        // Actualiser la liste des clients après la mise à jour
        axios
          .get(APP_URL + "client", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setClients(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id_cli) => {
    const ConfirmDelete = window.confirm(
      "Êtes vous sure de vouloir supprimer cette utilisateur?"
    );
    if (ConfirmDelete) {
      axios
        .delete(`${APP_URL}client/${id_cli}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setClients(clients.filter((client) => client.id_client !== id_cli));

          console.log("Client supprimé avec succès");
          window.location = "/Listclient"
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Sidebar>
        <div className="container mt-5">
          <h2>Liste des clients</h2>

          <div className="listveh table-responsive">
            {clients ? (
              <table className="table tabveh">
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Prénom</th>
                    <th>Email</th>
                    <th>Adresse</th>
                    <th>Téléphone</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clients.map((client) => (
                    <tr key={client.id_cli}>
                      <td>{client.nom}</td>
                      <td>{client.prenom}</td>
                      <td>{client.email}</td>
                      <td>{client.adresse}</td>
                      <td>{client.tel}</td>
                      <td>{client.role}</td>
                      <td className="p-3">
                        <a
                          className="text-warning"
                          onClick={() => handleEdit(client)}
                        >
                          <i className="fas fa-edit"></i>
                        </a>
                        &nbsp;&nbsp;
                        <a
                          className="text-danger"
                          onClick={() => handleDelete(client.id_cli)}
                        >
                          <i className="fas fa-trash"></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h1>Loading...</h1>
            )}
          </div>
        </div>
      </Sidebar>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier le client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicNom">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                value={editingClient ? editingClient.nom : ""}
                onChange={(e) =>
                  setEditingClient({ ...editingClient, nom: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formBasicPrenom">
              <Form.Label>Prénom</Form.Label>
              <Form.Control
                type="text"
                value={editingClient ? editingClient.prenom : ""}
                onChange={(e) =>
                  setEditingClient({ ...editingClient, prenom: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={editingClient ? editingClient.email : ""}
                onChange={(e) =>
                  setEditingClient({ ...editingClient, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formBasicAdresse">
              <Form.Label>Adresse</Form.Label>
              <Form.Control
                type="text"
                value={editingClient ? editingClient.adresse : ""}
                onChange={(e) =>
                  setEditingClient({
                    ...editingClient,
                    adresse: e.target.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formBasicTel">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control
                type="tel"
                value={editingClient ? editingClient.tel : ""}
                onChange={(e) =>
                  setEditingClient({ ...editingClient, tel: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formBasicRole">
              <Form.Label>Rôle</Form.Label>
              <Form.Control
                type="text"
                value={editingClient ? editingClient.role : ""}
                onChange={(e) =>
                  setEditingClient({ ...editingClient, role: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button variant="success" onClick={handleUpdate}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ListClient;
