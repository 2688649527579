import React, { useEffect, useState } from "react";
import Sidebar from "../../admin/Sidebar/Sidebar";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { APP_URL } from "../../../URL";

const Info_ship = () => {
  const location = useLocation();
  const reservation = location.state?.reservation;
  const [idCli, setIdCli] = useState("");
  const [role, setRole] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(window.atob(payloadBase64));
        setIdCli(payloadJSON.clientId);
        setRole(payloadBase64.role);
      } catch (error) {
        console.log("Erreur de décodage du token JWT", error);
      }
    }
  }, [token]);

  const [shipper, setShipper] = useState({
    nom: "",
    prenom: "",
    adresse: "",
    telephone: "",
    email: "",
  });

  const [transitaire, setTransitaire] = useState({
    nom: "",
    prenom: "",
    email: "",
    telephone: "",
    adresse: "",
  });

  const [destinataire, setDestinataire] = useState({
    nom: "",
    prenom: "",
    adresse: "",
    telephone: "",
    email: "",
  });

  const [liste_vehicule, setListe_vehicule] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  useEffect(() => {
    console.log("Données de réservation reçues : ", reservation); // Ajoutez ce log

    if (!reservation) {
      console.log("Aucune réservation trouvée dans l'état");
      return;
    }

    axios
      .get(APP_URL + "vechicule", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) //Filtrer par statut
      .then((response) => {
        const allVehicles = response.data;
        const filteredVehicles = allVehicles.filter(
          (vehicle) => vehicle.status === 1
        );

        setListe_vehicule(allVehicles);
        setFilteredVehicles(filteredVehicles);

        // Affichage de tous les véhicules récupérés
        console.log("Tous les véhicules : ", allVehicles);
        console.log("Véhicules filtrés : ", filteredVehicles);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reservation, token]);

  const handleAddVehicle = async (vehicle) => {
    try {
      await axios.post(APP_URL + "addvehicule", {
        id_shipping: reservation.id_shipping,
        id_vehicule: vehicle.id_vehicule,
      });
      setSelectedVehicles([...selectedVehicles, vehicle]);
      alert("Véhicule ajouté au shipping avec succès");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          alert("Ce véhicule a déjà été ajouté au shipping."); // Affiche un message spécifique
        } else {
          console.error(
            "Erreur lors de l'ajout du véhicule",
            error.response.data.message
          );
        }
      } else {
        console.error("Erreur lors de l'ajout du véhicule", error);
      }
    }
  };

  const handleRemoveVehicle = async (vehicle) => {
    try {
      await axios.post(APP_URL + "removevehicule", {
        id_shipping: reservation.id_shipping,
        id_vehicule: vehicle.id_vehicule,
      });
      setSelectedVehicles([...selectedVehicles, vehicle]);
      alert("Véhicule supprimé du shipping avec succès");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          alert("Ce véhicule a déjà été suprimé du shipping."); // Affiche un message spécifique
        } else {
          console.error(
            "Erreur lors de l'ajout du véhicule",
            error.response.data.message
          );
        }
      } else {
        console.error("Erreur lors de l'ajout du véhicule", error);
      }
    }
  };

  // gestion palette
  const [liste_palette, setListe_palette] = useState([]);
  const [filteredPalettes, setFilteredPalettes] = useState([]);
  const [selectedPalettes, setSelectedPalettes] = useState([]);

  // Fetching palettes
  useEffect(() => {
    if (!reservation) {
      console.log("Aucune réservation trouvé");
      return;
    }
    axios
      .get(APP_URL + "listePaletteClient", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const allPalettes = response.data;
        const filteredPalettes = allPalettes.filter(
          (palette) => palette.status === 1
        );

        setListe_palette(allPalettes);
        setFilteredPalettes(filteredPalettes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [reservation, token]);

  // ajouter palette au shipping
  const handleAddPalette = async (palette) => {
    try {
      await axios.post(APP_URL + "addpalettetoship", {
        id_shipping: reservation.id_shipping,
        id_palette: palette.id_pal,
      });
      setSelectedPalettes([...selectedPalettes, palette]);
      alert("Palette ajoutée au shipping avec succès");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          alert("Cette palette a déjà été ajoutée au shipping.");
        } else {
          console.error(
            "Erreur lors de l'ajout de la palette",
            error.response.data.message
          );
        }
      } else {
        console.error("Erreur lors de l'ajout de la palette", error);
      }
    }
  };

  // retirer palette du shipping
  const handleRemovePalette = async (palette) => {
    try {
      await axios.post(APP_URL + "removepalette", {
        id_shipping: reservation.id_shipping,
        id_palette: palette.id_pal,
      });
      setSelectedPalettes(
        selectedPalettes.filter((p) => p.id_pal !== palette.id_pal)
      );
      alert("Palette supprimée du shipping avec succès");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          alert("Cette palette a déjà été supprimée du shipping.");
        } else {
          console.error(
            "Erreur lors de la suppression de la palette",
            error.response.data.message
          );
        }
      } else {
        console.error("Erreur lors de la suppression de la palette", error);
      }
    }
  };

  // fin gestion palette

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!reservation) {
        alert("Aucune réservation trouvée");
        return;
      }

      const shippingData = {
        id_book: reservation.id_book,
        id_shipping: reservation.id_shipping,
        numero_conteneur: reservation.numero_conteneur,
        shipper,
        transitaire,
        destinataire,
        vehicles: selectedVehicles.map((vehicle) => vehicle.id_vehicule),
        palettes: selectedPalettes.map((palette) => palette.id_palette),
      };

      const response = await axios.put(
        APP_URL + `updateShipping/${reservation.id_shipping}`,
        shippingData
      );

      if (response.data.draftCreated) {
        alert("Shipping mis à jour et draft créé avec succès");
      } else {
        alert(
          "Shipping mis à jour avec succès, mais échec de la création du draft"
        );
      }
      window.location = "/draft";
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour des informations de shipping",
        error
      );
    }
  };

  return (
    <Sidebar>
      <h1 className="p-4">Informations du shipping</h1>
      <h2>Réservation détaillée</h2>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <h3>Shipper</h3>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="nom">Nom</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="nom"
                      value={shipper.nom}
                      onChange={(e) =>
                        setShipper({ ...shipper, nom: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="prenom">Prénom</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="prenom"
                      value={shipper.prenom}
                      onChange={(e) =>
                        setShipper({ ...shipper, prenom: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="adresse"
                      value={shipper.adresse}
                      onChange={(e) =>
                        setShipper({ ...shipper, adresse: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="telephone">Téléphone</label>
                    <input
                      required
                      type="number"
                      className="form-control"
                      name="telephone"
                      value={shipper.telephone}
                      onChange={(e) =>
                        setShipper({ ...shipper, telephone: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      required
                      type="email"
                      className="form-control"
                      name="email"
                      value={shipper.email}
                      onChange={(e) =>
                        setShipper({ ...shipper, email: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card">
                <h3>Transitaire à destination</h3>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="nom">Nom</label>
                    <input
                      required
                      type="text"
                      className="form-control"
                      name="nom"
                      value={transitaire.nom}
                      onChange={(e) =>
                        setTransitaire({
                          ...transitaire,
                          nom: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="prenom">Prénom</label>
                    <input
                      type="text"
                      className="form-control"
                      name="prenom"
                      value={transitaire.prenom}
                      onChange={(e) =>
                        setTransitaire({
                          ...transitaire,
                          prenom: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      className="form-control"
                      name="adresse"
                      value={transitaire.adresse}
                      onChange={(e) =>
                        setTransitaire({
                          ...transitaire,
                          adresse: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="telephone">Téléphone</label>
                    <input
                      type="number"
                      className="form-control"
                      name="telephone"
                      value={transitaire.telephone}
                      onChange={(e) =>
                        setTransitaire({
                          ...transitaire,
                          telephone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={transitaire.email}
                      onChange={(e) =>
                        setTransitaire({
                          ...transitaire,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card">
                <h3>Destinataire</h3>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="nom">Nom</label>
                    <input
                      type="text"
                      className="form-control"
                      name="nom"
                      value={destinataire.nom}
                      onChange={(e) =>
                        setDestinataire({
                          ...destinataire,
                          nom: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="prenom">Prénom</label>
                    <input
                      type="text"
                      className="form-control"
                      name="prenom"
                      value={destinataire.prenom}
                      onChange={(e) =>
                        setDestinataire({
                          ...destinataire,
                          prenom: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="adresse">Adresse</label>
                    <input
                      type="text"
                      className="form-control"
                      name="adresse"
                      value={destinataire.adresse}
                      onChange={(e) =>
                        setDestinataire({
                          ...destinataire,
                          adresse: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="telephone">Téléphone</label>
                    <input
                      type="number"
                      className="form-control"
                      name="telephone"
                      value={destinataire.telephone}
                      onChange={(e) =>
                        setDestinataire({
                          ...destinataire,
                          telephone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={destinataire.email}
                      onChange={(e) =>
                        setDestinataire({
                          ...destinataire,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12">
              <h3>Liste des véhicules</h3>
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Marque</th>
                    <th>Modèle</th>
                    <th>Année</th>
                    <th>Couleur</th>
                    <th>Destination</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {liste_vehicule.map((vehicle) => {
                    const isSelected = selectedVehicles.some(
                      (v) => v.id_vehicule === vehicle.id_vehicule
                    );
                    const uniqueKey = `${vehicle.id_vehicule}-${vehicle.marque}-${vehicle.mode}`;
                    return (
                      <tr key={uniqueKey}>
                        <td>{vehicle.id_vehicule}</td>
                        <td>{vehicle.marque}</td>
                        <td>{vehicle.mode}</td>
                        <td>{vehicle.annee}</td>
                        <td>{vehicle.couleur}</td>
                        <td>{vehicle.destination}</td>

                        <td>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveVehicle(vehicle)}
                          >
                            -
                          </button>

                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleAddVehicle(vehicle)}
                          >
                            +
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          {/* palette */}
          <div className="row mt-4">
            <div className="col-12">
              <h3>Liste des palettes</h3>
              <table className="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {liste_palette.map((palette) => {
                    const isSelected = selectedPalettes.some(
                      (p) => p.id_pal === palette.id_pal
                    );
                    //const uniqueKey = `${palette.id_pal}-${palette.description}`;
                    return (
                      <tr key={palette.id_pal}>
                        <td>{palette.id_pal}</td>
                        <td>{palette.description}</td>
                        <td>{palette.type_palette}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemovePalette(palette)}
                          >
                            -
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => handleAddPalette(palette)}
                          >
                            +
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-12 text-center">
              <button type="submit" className="btn btn-success">
                Enregistrer les informations du shipping
              </button>
            </div>
          </div>
        </form>
      </div>
    </Sidebar>
  );
};

export default Info_ship;
