import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { APP_URL } from "../../../URL";

const Draft = () => {
  const [drafts, setDrafts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDraft, setCurrentDraft] = useState(null);

  useEffect(() => {
    axios
      .get(APP_URL + "getAlldraft")
      .then((response) => {
        setDrafts(response.data);
      })
      .catch((error) => {
        console.error("Erreur de chargement des drafts!", error);
      });
  }, []);

  const handleDownloadPdf = (draftId) => {
    axios
      .get(APP_URL + `generatePdf/${draftId}`, {
        responseType: "blob",
        withCredentials: true,
      })
      .then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `shipping_instructions_${draftId}.pdf`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        document.body.removeChild(fileLink);
        window.URL.revokeObjectURL(fileURL);
      })
      .catch((error) => {
        console.error("Erreur lors de la génération du PDF!", error);
      });
  };

  const openModal = (draft) => {
    setCurrentDraft(draft);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentDraft(null);
  };

  const handleUpdateDraft = (e) => {
    e.preventDefault();
    axios
      .put(APP_URL + `updateDraft/${currentDraft.id_draft}`, currentDraft)
      .then((response) => {
        console.log("Draft updated successfully:", response.data);
        setDrafts((prevDrafts) =>
          prevDrafts.map((draft) =>
            draft.id_draft === currentDraft.id_draft ? currentDraft : draft
          )
        );
        closeModal();
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour du draft!", error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentDraft({ ...currentDraft, [name]: value });
  };

  return (
    <>
      <Sidebar>
        <div className="container">
          <h1>Draft</h1>
          <div className="table table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Numéro draft</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {drafts.map((draft) => (
                  <tr key={draft.id_draft}>
                    <td>{draft.id_draft}</td>
                    <td>
                      <button
                        className="btn btn-info"
                        onClick={() => handleDownloadPdf(draft.id_draft)}
                      >
                        Télécharger <i className="fas fa-file"></i>
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-warning"
                        onClick={() => openModal(draft)}
                      >
                        Modifier <i className="fas fa-edit"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Modal
            show={isModalOpen}
            onHide={closeModal}
            dialogClassName="modal-dialog-scrollable"
          >
            <Modal.Header closeButton>
              <Modal.Title>Modifier le Draft</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
              {currentDraft && (
                <Form onSubmit={handleUpdateDraft}>
                  <Form.Group controlId="formLignema">
                    <Form.Label>Ligne Maritime</Form.Label>
                    <Form.Control
                      type="text"
                      name="lignema"
                      value={currentDraft.lignema}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formDestination">
                    <Form.Label>Destination</Form.Label>
                    <Form.Control
                      type="text"
                      name="destination"
                      value={currentDraft.destination}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formDateCharge">
                    <Form.Label>Date Chargement</Form.Label>
                    <Form.Control
                      type="text"
                      name="date_charge"
                      value={currentDraft.date_charge}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formTypConte">
                    <Form.Label>Type de conteneur</Form.Label>
                    <Form.Control
                      type="text"
                      name="type_conteneur"
                      value={currentDraft.type_conteneur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formNumCont">
                    <Form.Label>Numero de conteneur</Form.Label>
                    <Form.Control
                      type="text"
                      name="numero_conteneur"
                      value={currentDraft.numero_conteneur}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formClientName">
                    <Form.Label>Nom du Client</Form.Label>
                    <Form.Control
                      type="text"
                      name="client_nom"
                      value={currentDraft.client_nom}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formClientEmail">
                    <Form.Label>Email du Client</Form.Label>
                    <Form.Control
                      type="email"
                      name="client_email"
                      value={currentDraft.client_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {/* info shipper */}
                  <Form.Group controlId="formShipperNom">
                    <Form.Label>Nom Shipper</Form.Label>
                    <Form.Control
                      type="text"
                      name="shipper_nom"
                      value={currentDraft.shipper_nom}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formShipperPrenom">
                    <Form.Label>Prenom du Shipper</Form.Label>
                    <Form.Control
                      type="text"
                      name="shipper_prenom"
                      value={currentDraft.shipper_prenom}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formShipperAdresse">
                    <Form.Label>Adresse Shipper</Form.Label>
                    <Form.Control
                      type="text"
                      name="shipper_adresse"
                      value={currentDraft.shipper_adresse}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formShipperEmail">
                    <Form.Label>Shipper Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="shipper_email"
                      value={currentDraft.shipper_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {/* fin info shipper */}

                  {/* info transitaire */}
                  <Form.Group controlId="formTransitaireNom">
                    <Form.Label>Nom Transitaire</Form.Label>
                    <Form.Control
                      type="text"
                      name="transitaire_nom"
                      value={currentDraft.transitaire_nom}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formTransitairePrenom">
                    <Form.Label>Prenom du Transitaire</Form.Label>
                    <Form.Control
                      type="text"
                      name="transitaire_prenom"
                      value={currentDraft.transitaire_prenom}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formTransitaireAdresse">
                    <Form.Label>Adresse Transitaire</Form.Label>
                    <Form.Control
                      type="text"
                      name="transitaire_adresse"
                      value={currentDraft.transitaire_adresse}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formTransitaireEmail">
                    <Form.Label>Transitaire Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="transitaire_email"
                      value={currentDraft.transitaire_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {/* fin info transitaire */}

                  {/* info destinataire */}
                  <Form.Group controlId="formdestinataireNom">
                    <Form.Label>Nom destinataire</Form.Label>
                    <Form.Control
                      type="text"
                      name="destinataire_nom"
                      value={currentDraft.destinataire_nom}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formdestinatairePrenom">
                    <Form.Label>Prenom du destinataire</Form.Label>
                    <Form.Control
                      type="text"
                      name="destinataire_prenom"
                      value={currentDraft.destinataire_prenom}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formdestinataireAdresse">
                    <Form.Label>Adresse destinataire</Form.Label>
                    <Form.Control
                      type="text"
                      name="destinataire_adresse"
                      value={currentDraft.destinataire_adresse}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group controlId="formdestinataireEmail">
                    <Form.Label>Destinataire Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="destinataire_email"
                      value={currentDraft.destinataire_email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  {/* fin info destinataire */}
                  <Button variant="primary" type="submit" className="mt-2">
                    Enregistrer les modifications
                  </Button>
                </Form>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </Sidebar>
    </>
  );
};

export default Draft;
