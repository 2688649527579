import React from "react";
import NavVue from "./structure/NavVue";
import FooterVue from "./structure/FooterVue";
import carrousel1 from "../img/africa/Major-Ports-In-Chile.png";
import carousel2 from "../img/africa/Port-Autonome-de-Douala-scaled.png";
import backgroundAbout from "../img/abstract-white-color-canvas-wallpaper-textures-surface.jpg";
import about from "../img/africa/port-de-kribi-780x440.jpg";
import feature from "../img/africa/46586hr_-592x296-1606382377.jpg";
import Imgdevis from "../img/gray-abstract-wireframe-technology-background.jpg";
import img1 from "../img/aerial-view-container-cargo-ship-sea.jpg";
import img2 from "../img/2149288188.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Index = () => {
  return (
    <div>
      <nav
        className="fixed-top-navbar"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
        }}
      >
        <NavVue />
      </nav>
      {/* <!-- Carousel Start --> */}
      <OwlCarousel
        className="owl-theme"
        loop
        margin={0}
        autoplay
        autoplayTimeout={3000}
        autoplayHoverPause={false}
        items={1}
        dots={false}
      >
        <div className="item">
          <img
            className="img-fluid"
            src={carrousel1}
            alt="caroussel1"
            style={{ width: "100%", height: "auto" }}
          />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(6, 3, 21,.5)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-12 col-md-10 col-lg-8 carousel-text">
                  <h5
                    className="text-white text-uppercase mb-3 animated slideInDown"
                    style={{
                      fontSize: "34px",
                      fontWeight: "bold",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    Transport et solution logistique
                  </h5>
                  <h1
                    className="display-3 text-white animated slideInDown mb-4"
                    style={{
                      fontSize: "58px",
                      fontWeight: "bold",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    N°1 pour vos Solutions de{" "}
                    <span
                      className="text-success fw-bold"
                      style={{ color: "#90ee90" }}
                    >
                      Logistique
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="item">
          <img
            className="img-fluid"
            src={carousel2}
            alt="caroussel2"
            style={{ width: "100%", height: "auto" }}
          />
          <div
            className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style={{ background: "rgba(6, 3, 21,.5)" }}
          >
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-12 col-md-10 col-lg-8 carousel-text">
                  <h5
                    className="text-white text-uppercase mb-3 animated slideInDown"
                    style={{
                      fontSize: "34px",
                      fontWeight: "bold",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    Transport & Solution logistique
                  </h5>
                  <h1
                    className="display-3 text-white animated slideInDown mb-4"
                    style={{
                      fontSize: "58px",
                      fontWeight: "bold",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    N°1 pour vos Solutions de{" "}
                    <span
                      className="text-primary fw-bold"
                      style={{ color: "#ff6347" }}
                    >
                      Transport
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
      {/* <!-- Carousel End --> */}

      {/* <!-- About Start --> */}
      <div
        className="container-fluid overflow-hidden py-5 px-lg-0"
        style={{
          backgroundImage: `url(${backgroundAbout})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "20px",
          opacity: 1,
        }}
      >
        <div
          className="container about py-5 px-lg-0"
          style={{
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0 wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div
                className="position-relative h-100"
                style={{ marginLeft: "14px" }}
              >
                <img
                  className="position-absolute img-fluid w-100 h-100 about-image "
                  src={about}
                  style={{ objectFit: "cover", borderRadius: "40px" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div style={{ marginLeft: "25px" }}>
                <h6 className="text-secondary text-uppercase mb-3">
                  A propos de Nous
                </h6>
                <h1 className="mb-5">
                  Solutions de transport et de logistique rapides
                </h1>
                <p className="mb-5">
                  Avec notre expertise et notre réseau étendu de partenaires,
                  nous vous garantissons des délais de livraison ultra-courts,
                  tout en assurant un suivi constant de vos colis. Que ce soit
                  pour des expéditions urgentes ou des envois planifiés, nous
                  mettons tout en œuvre pour vous faire bénéficier d'un service
                  de qualité supérieure.
                </p>
                <div className="row g-4 mb-5">
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                    <i className="fa fa-globe fa-3x text-success mb-3"></i>
                    <h5>Couverture Mondiale</h5>
                    <p className="m-0">
                      Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam
                      lorem diam justo.
                    </p>
                  </div>
                  <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                    <i className="fa fa-shipping-fast fa-3x text-success mb-3"></i>
                    <h5>Livraison dans les délais</h5>
                    <p className="m-0">
                      Stet stet justo dolor sed duo. Ut clita sea sit ipsum diam
                      lorem diam justo.
                    </p>
                  </div>
                </div>
                <a href="/about" className="btn btn-success py-3 px-5">
                  Voir plus...
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Fact Start --> */}
      <div className="container- py-5" style={{ background: "#fff" }}>
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-secondary text-uppercase mb-3">
                QUELQUES FAITS
              </h6>
              <h1 className="mb-5">Lieu de gestion de tous vos envois</h1>
              <p className="mb-5">
                Chez <span className="fw-bold">WATISEERA</span>, nous comprenons
                les défis de la logistique moderne. Notre équipe se consacre à
                fournir des solutions fiables et efficaces pour répondre à vos
                besoins. Que vous géiez une petite flotte ou coordoniiez des
                expéditions internationales, nous sommes là pour assurer la
                livraison sûre et à temps de vos marchandises.
              </p>
              <div className="d-flex align-items-center">
                <i className="fa fa-phone fa-2x flex-shrink-0 bg-success p-3 text-white"></i>
                <div className="ps-4">
                  <h6>Appeler pour toute question!</h6>
                  <h3 className="text-success m-0">+012 345 6789</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row g-4 align-items-center">
                <div className="col-sm-6">
                  <div
                    className="p-4 mb-4 wow fadeIn"
                    data-wow-delay="0.3s"
                    style={{ background: "#e8be55" }}
                  >
                    <i className="fa fa-users fa-2x text-white mb-3"></i>
                    <h2 className="text-white mb-2" data-toggle="counter-up">
                      1234
                    </h2>
                    <p className="text-white mb-0">Clients Satisfaits</p>
                  </div>
                  <div
                    className="bg-danger p-4 wow fadeIn"
                    data-wow-delay="0.5s"
                  >
                    <i className="fa fa-ship fa-2x text-white mb-3"></i>
                    <h2 className="text-white mb-2" data-toggle="counter-up">
                      1234
                    </h2>
                    <p className="text-white mb-0">Envois complets</p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div
                    className="bg-success p-4 wow fadeIn"
                    data-wow-delay="0.7s"
                  >
                    <i className="fa fa-star fa-2x text-white mb-3"></i>
                    <h2 className="text-white mb-2" data-toggle="counter-up">
                      1234
                    </h2>
                    <p className="text-white mb-0">Avis des clients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Fact End --> */}

      <div className="container">
        <hr width="100%" />
      </div>

      {/* <!-- Feature Start --> */}
      <div
        className="container-fluid overflow-hidden py-5 px-lg-0"
        style={{
          backgroundImage: `url(${backgroundAbout})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "20px",
          opacity: 1,
        }}
      >
        <div className="container feature py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 feature-text wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <h6 className="text-secondary text-uppercase mb-3">
                Nos caractéristiques
              </h6>
              <h1 className="mb-5">
                Nous sommes une entreprise de logistique de confiance
              </h1>
              <div className="d-flex mb-5 wow fadeInUp" data-wow-delay="0.3s">
                <i
                  className="fa fa-globe  fa-3x flex-shrink-0"
                  style={{ color: "green" }}
                ></i>
                <div className="ms-4">
                  <h5>Service Mondiale</h5>
                  <p className="mb-0">
                    Nous offrons des services de transit à l'échelle mondiale
                    pour vous connecter à des destinations partout dans le
                    monde. Peu importe où vous êtes, nous facilitons vos
                    expéditions à l'étranger.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-5 wow fadeIn" data-wow-delay="0.5s">
                <i
                  className="fa fa-shipping-fast fa-3x flex-shrink-0"
                  style={{ color: "green" }}
                ></i>
                <div className="ms-4">
                  <h5>Livraison Rapide</h5>
                  <p className="mb-0">
                    Notre priorité est de vous offrir une livraison rapide et
                    fiable. Avec nos services de transit, vous pouvez compter
                    sur nous pour que vos marchandises arrivent à destination à
                    temps, chaque fois.
                  </p>
                </div>
              </div>
              <div className="d-flex mb-0 wow fadeInUp" data-wow-delay="0.7s">
                <i
                  className="fa fa-phone fa-3x flex-shrink-0"
                  style={{ color: "green" }}
                ></i>
                <div className="ms-4">
                  <h5>Support technique 24/7</h5>
                  <p className="mb-0">
                    Notre équipe de support est disponible 24 heures sur 24, 7
                    jours sur 7 pour répondre à vos questions et résoudre vos
                    problèmes. Quelle que soit l'heure, nous sommes là pour vous
                    aider.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 pe-lg-0 wow fadeInRight"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div
                className="position-relative h-100"
                style={{ marginRight: "15px" }}
              >
                <img
                  className="position-absolute img-fluid w-100 h-100 about-image"
                  src={feature}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Feature End --> */}

      {/* <!-- Quote Start --> */}
      <div
        className="container-x py-5"
        style={{
          backgroundImage: `url(${Imgdevis})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "20px",
        }}
      >
        <div
          className="overlays"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",

            zIndex: 1,
          }}
        ></div>
        <div className="container py-5">
          <div className="row g-5 align-items-center">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <h6 className="text-secondary text-uppercase mb-3">
                Avoir un devis
              </h6>
              <h1 className="mb-5">Demander un devis gratuit!</h1>
              <p className="mb-5">
                Besoin d'une estimation pour votre expédition ? Nous sommes là
                pour vous aider.
              </p>
              <div className="d-flex align-items-center">
                <i className="fa fa-phone fa-2x flex-shrink-0 bg-success p-3 text-white"></i>
                <div className="ps-4">
                  <h6>Appeler pour toute question!</h6>
                  <h3 className="text-dark m-0">+1 (438) 938-5616</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="text-center p-5 wow fadeIn" data-wow-delay="0.5s">
                <form>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        className="form-control border-0"
                        placeholder="Votre Nom"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="email"
                        className="form-control border-0"
                        placeholder="Votre Email"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input
                        type="text"
                        className="form-control border-0"
                        placeholder="Numéro de téléphone"
                        style={{ height: "55px" }}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <select
                        className="form-select border-0"
                        style={{ height: "55px" }}
                      >
                        <option selected>Service</option>
                        <option value="1">Réservation parking</option>
                        <option value="2">Réservation Palette</option>
                        <option value="3">Réservation conteneur</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <textarea
                        className="form-control border-0"
                        placeholder="Description"
                      ></textarea>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-success w-100 py-3"
                        type="submit"
                      >
                        Envoyer
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* image du fond */}
        <div className="container-fluid py-5">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="image-content-container">
                <img src={img1} height="100%" width="100%" alt="Image 1" />
                <div className="content-below-image text-center">
                  <h2 className="text-success ">
                    La gestion en ligne de chaque étape de votre cycle de
                    transport
                  </h2>
                  <a href="/login" className="btn btn-danger mt-4">
                    Se connecter
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="image-content-container">
                <img src={img2} height="100%" width="100%" alt="Image 2" />
                <div className="content-below-image text-center">
                  <h2 className="text-success ">
                    Des questions ? Nous sommes là pour vous aider. Découvrez le
                    portail My Customer Service et obtenez vos réponses !
                  </h2>
                  <a href="/contact" className="btn btn-danger mt-4">
                    Contactez nous!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterVue />
    </div>
  );
};

export default Index;
