import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/security/Login";
import Register from "./components/security/Register";
import Dashboard from "./components/admin/Dashboard";
import Vehicule from "./components/User/vehicule/Vehicule";
import Booking from "./components/User/Booking/Booking";
import Shipping from "./components/User/Shipping/Shipping";
import Index from "./components/vue/Index";
import ListClient from "./components/admin/Client/ListClient";
import Services from "./components/vue/structure/Services";
import Contact from "./components/vue/Contact";
import Voiture_res from "./components/User/vehicule/Voiture_res";
import BookingsAdmin from "./components/admin/bookings/bookingsAdmin";
import ListBooking from "./components/admin/bookings/ListBookings";
import About from "./components/vue/structure/About";
import Espace from "./components/admin/espace/Espace";
import Info_ship from "./components/User/information_shipping/Info_ship";
import Draft from "./components/admin/draft/Draft";
import Transport_maritime from "./components/vue/structure/Transport_maritime";
import Transport_terrestre from "./components/vue/structure/Transport_terrestre";
import Chargement_conteneur from "./components/vue/structure/Chargement_conteneur";
import Entreposage from "./components/vue/structure/Entreposage";
import MotDePasseOublie from "./components/security/password/MotDePasseOublie";
import ResetMotDePasse from "./components/security/password/ResetMotDePasse";
import Palette from "./components/User/Palette/Palette";

const Chemin = () => {
  return (
    <Router>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/vehicule" element={<Vehicule />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/shipping" element={<Shipping />} />
        <Route path="/palette" element={<Palette />} />

        {/* la vue client */}
        <Route path="/" element={<Index />} />
        <Route path="services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/transportMari" element={<Transport_maritime />} />
        <Route path="/transportTerres" element={<Transport_terrestre />} />
        <Route path="/chargementCont" element={<Chargement_conteneur />} />
        <Route path="/Entreposage" element={<Entreposage />} />

        {/* client */}

        <Route path="/Listclient" element={<ListClient />} />

        {/* admin */}
        <Route path="/Addbooking" element={<BookingsAdmin />} />
        <Route path="/ListBooking" element={<ListBooking />}></Route>

        {/* espace */}
        <Route path="/creerEspace" element={<Espace />} />

        {/* Informations du shipping */}
        <Route path="/info_ship" element={<Info_ship />} />

        {/* draft */}
        <Route path="/draft" element={<Draft />} />

        {/* reset password */}
        <Route
          path="/motdepasseoublie"
          element={<MotDePasseOublie/>}
        ></Route>
        <Route
          path="/resetpassword/:token"
          element={<ResetMotDePasse/>}
        ></Route>
      </Routes>
    </Router>
  );
};

export default Chemin;
