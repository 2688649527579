import React from "react";
import NavVue from "./NavVue";
import FooterVue from "./FooterVue";
import chargement from "../../img/full-shot-man-carrying-box.jpg";
import bgmaritime from "../../img/gray-abstract-wireframe-technology-background.jpg";
import chargementIMG from "../../img/africa/1666900745183.jpeg";

const Chargement_conteneur = () => {
  return (
    <div>
      <NavVue />
      {/* <!-- Page Header Start --> */}
      <div
        className="container-fluid page-header py-5"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${chargementIMG})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover", // Assurez-vous que l'image couvre tout l'espace disponible
          height: "600px", // Ajustez cette valeur selon vos besoins
        }}
      >
        <div
          className="container py-5"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h1 className="display-3 text-white mb-3 animated slideInDown fw-bolder">
            Service/Chargement de Conteneur
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Accueil
                </a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Service
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      <header className="py-5" style={{ backgroundColor: "#f8f9fa" }}>
        <div className="container">
          <h1 className="display-4 fw-bold">
            Services Chargement de conteneur
          </h1>
          <p className="lead">
            Optimisez vos opérations de chargement avec notre expertise.
          </p>
        </div>
      </header>

      <main
        className="container-"
        style={{
          backgroundImage: `url(${bgmaritime})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          padding: "20px",
          opacity: 1,
        }}
      >
        <div className="container">
          <section className="row">
            <div className="col-lg-6">
              <h2 className="mt-5">Expertise et Infrastructure</h2>
              <p>
                Notre équipe dispose d'une solide expérience dans le chargement
                de conteneurs, avec une infrastructure logistique conçue pour
                gérer efficacement vos besoins de transport. Que vous soyez un
                importateur/exportateur ou un opérateur de transport, nous avons
                les compétences nécessaires pour vous soutenir.
              </p>
            </div>
            <div className="col-lg-6">
              <img
                src={chargement}
                alt="Transport Maritime"
                className="img-fluid rounded"
              />
            </div>
          </section>

          <section className="mt-5 p-5 fs-4">
            <h2>Services Offerts</h2>
            <ul>
              <li>
                <strong>Chargement de Conteneurs :</strong> Expédition et
                importation de conteneurs vers et depuis le monde entier.
              </li>
              <li>
                <strong>Gestion de Stock :</strong> Stockage sécurisé et gestion
                des stocks pour optimiser vos opérations de chargement.
              </li>
              <li>
                <strong>Consultation Logistique :</strong> Conseil et assistance
                pour optimiser vos processus de chargement de conteneurs.
              </li>
              <li>
                <strong>Service Client :</strong> Assistance et conseil pour
                planifier et gérer vos opérations de chargement de conteneurs.
              </li>
            </ul>
          </section>
        </div>
      </main>

      {/* contact */}
      <div className="container- py-5" style={{ backgroundColor: "#acc03b" }}>
        <div className="container py-5">
          <div className="row g-4">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="mb-4 text-light">CONTACTER NOUS!</h1>
              </div>
              <div>
                <p className="fw-bold">
                  Chez{" "}
                  <span className="fw-bold text-success">
                    WATISEERAMARITIME
                  </span>
                  , nous comprenons les complexités de la logistique et du
                  transport modernes.Notre équipe se consacre à à fournir des
                  solutions fiables et transparentes, adaptées à vos uniques.
                  Discutons ensemble de la manière dont nous pouvons améliorer
                  votre stratégie logistique.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="text-center">
                <i
                  className="fas fa-phone feature-icon mb-3"
                  style={{ fontSize: "3rem", color: "#1b702f" }}
                ></i>
                <p className="fw-bold fs-4">Nous joindre : +1 (438) 938-5616</p>
              </div>
              <div className="text-center">
                <i
                  className="fas fa-envelope feature-icon mb-3"
                  style={{ fontSize: "3rem", color: "#1b702f" }}
                ></i>
                <span>
                  <p className="fw-bold fs-4">
                    Email: contact@watiseeramaritime.com
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* contact */}

      <FooterVue />
    </div>
  );
};

export default Chargement_conteneur;
