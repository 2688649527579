import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import logoboat from "../../img/logo Watiseeramaritime-02 1.png";

const NavVue = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("Accueil");

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/":
        setActiveLink("Accueil");
        break;
      case "/about":
        setActiveLink("A propos");
        break;
      case "/services":
        setActiveLink("Services");
        break;
      case "/contact":
        setActiveLink("Contact");
        break;
      case "/login":
        setActiveLink("Se connecter");
        break;
      default:
        setActiveLink("");
        break;
    }
  }, [location]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-success border-5 sticky-top p-0 custom-navbar">
        <a
          href="/"
          className="navbar-brand d-flex align-items-center px-4 px-lg-5"
        >
          <div>
            <img src={logoboat} alt="logo" width="130" />
            {/* Augmenter la taille du logo */}
          </div>
        </a>
        <button
          type="button"
          className="navbar-toggler me-4"
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          id="navbarCollapse"
        >
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <a
              href="/"
              className={`nav-item nav-link ${
                activeLink === "Accueil" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("Accueil")}
            >
              Accueil
            </a>
            <a
              href="/about"
              className={`nav-item nav-link ${
                activeLink === "A propos" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("A propos")}
            >
              A propos
            </a>
            <div className="nav-item dropdown">
              <a
                href="/services"
                className={`nav-link dropdown-toggle ${
                  activeLink === "Services" ? "active" : ""
                }`}
                data-bs-toggle="dropdown"
                onClick={() => handleLinkClick("Services")}
              >
                Services
              </a>
              <div className="dropdown-menu fade-up m-0">
                <a href="/transportTerres" className="dropdown-item">
                  Transport terrestre
                </a>
                <a href="/transportMari" className="dropdown-item">
                  Transport maritime
                </a>

                <a href="/chargementCont" className="dropdown-item">
                  Chargement de conteneur
                </a>
                {/* <a href="testimonial.html" className="dropdown-item">
                  Reservation de conteneur
                </a> */}
                <a href="/Entreposage" className="dropdown-item">
                  Entreposage
                </a>
              </div>
            </div>
            <a
              href="/contact"
              className={`nav-item nav-link ${
                activeLink === "Contact" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("Contact")}
            >
              Contact
            </a>
            <a
              href="/login"
              className={`nav-item nav-link text-lowercase ${
                activeLink === "Se connecter" ? "active" : ""
              }`}
              onClick={() => handleLinkClick("Se connecter")}
            >
              Se connecter
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavVue;
