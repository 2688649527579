import React, { useState, useEffect } from "react";
import "./login.css";
import Nav from "./Nav";
import axios from "axios";
import logo from "../img/logo Watiseeramaritime-02 1.png";
import { APP_URL } from "../../URL";

const Register = () => {
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [adresse, setAdresse] = useState("");
  const [tel, setTel] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiError, setApiError] = useState("");
  const [role, setRole] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const client = {
      nom: nom,
      prenom: prenom,
      email: email,
      adresse: adresse,
      tel: tel,
      password: password,
      confirm: confirm,
      role: "client",
    };

    axios
      .post(APP_URL + "client", client, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        alert("Création réussi");
        setNom("");
        setPrenom("");
        setEmail("");
        setAdresse("");
        setTel("");
        setPassword("");
        setConfirm("");
        window.location.href = "/login";
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          // Si l'erreur est une réponse de l'API
          const errorMessage = error.response.data.message;
          if (errorMessage.includes("mots de passe ne sont pas identiques")) {
            setPasswordError(errorMessage);
          } else {
            setApiError(errorMessage);
          }
        } else {
          // Si l'erreur est une erreur réseau ou autre
          setApiError("Une erreur est survenue. Veuillez réessayer plus tard.");
        }
      });
  };
  return (
    <>
      <div className="background-image"></div>
      <div className="overlay"></div>

      <div className="container cont">
        <button
          className="back-button"
          onClick={() => (window.location.href = "/")}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <div className="heading fw-bold fs-2 text-white text-center">
          <img src={logo} alt="logo" height="150px" />
        </div>
        <div className="heading fw-bold fs-3">Registration</div>
        <form action="" className="form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col lg 6">
              <input
                required=""
                className="input"
                type="text"
                name="nom"
                id="nom"
                placeholder="Nom"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
              <input
                required=""
                className="input"
                type="text"
                name="prenom"
                id="prenom"
                placeholder="Prenom"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
              <input
                required=""
                className="input"
                type="email"
                name="email"
                id="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col lg 6">
              <input
                required=""
                className="input"
                type="tel"
                name="tel"
                id="tel"
                placeholder="(123) 456-7890"
                // pattern="\(\d{3}\) \d{3}-\d{4}"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
              />
              <input
                required=""
                className="input"
                type="text"
                name="addresse"
                id="adresse"
                placeholder="Adresse"
                value={adresse}
                onChange={(e) => setAdresse(e.target.value)}
              />
              <input
                required=""
                className="input"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <input
            required=""
            className="input"
            type="password"
            name="password"
            id="password"
            placeholder="Confirm Password"
            value={confirm}
            onChange={(e) => setConfirm(e.target.value)}
          />
          <input className="login-button" type="submit" value="S'enregistrer" />
          {passwordError && (
            <div className="password-error fw-bolder text-white">
              {passwordError}
            </div>
          )}
          {apiError && (
            <div className="api-error fw-bolder text-white">{apiError}</div>
          )}
        </form>
        <div className="social-account-container">
          <span className="title">
            <a href="/login" className="fw-bold text-white fs-6">
              se connecter
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default Register;
