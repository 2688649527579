import React, { useState } from "react";
import "./login.css";
import axios from "axios";
import logo from "../img/logo Watiseeramaritime-02 1.png";
import { APP_URL } from "../../URL";

const Login = () => {
  const [loginData, setLoginData] = useState({
    password: "",
    email: "",
    error: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!loginData.password || !loginData.email) {
      setLoginData((prevState) => ({
        ...prevState,
        error: "Email ou password ne peuvent être vident",
      }));
      return;
    }

    axios
      .post(APP_URL + "login", loginData)
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem("token", token);
        if (response.data && response.data.success) {
          setLoginData((prevState) => ({
            ...prevState,
            error: "", // Reset the error
          }));
          window.location.href = "/dashboard";
        } else {
          setLoginData((prevState) => ({
            ...prevState,
            error: "Identifiants incorrects",
          }));
        }
      })
      .catch((error) => {
        setLoginData((prevState) => ({
          ...prevState,
          error: "Connexion échouée",
        }));
      });
  };

  return (
    <main>
      <div className="background-image"></div>
      <div className="overlay"></div>
      <div className="container cont">
        <button className="back-button" onClick={() => window.location.href = '/'}>
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
        <div className="heading fw-bold fs-2 text-white text-center">
          <img src={logo} alt="logo" height="150px" />
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <input
            required
            className="input"
            type="email"
            name="email"
            id="email"
            placeholder="E-mail"
            value={loginData.email}
            onChange={handleChange}
          />
          <input
            required
            className="input"
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            value={loginData.password}
            onChange={handleChange}
          />
          <a onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? (
              <i className="fa fa-eye-slash text-light" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-eye text-light" aria-hidden="true"></i>
            )}
          </a>
          <span className="forgot-password">
            <a href="/motdepasseoublie" className="fw-bold text-white fs-6">
              Mot de passe oublié?
            </a>
          </span>
          <input className="login-button" type="submit" value="Se connecter" />
          {loginData.error && (
            <div className="col-lg-12 login-btm login-text text-white fw-bold">
              {loginData.error}
            </div>
          )}
        </form>
        <div className="social-account-container">
          <span className="title">
            <a href="/register" className="fw-bold text-white fs-6">
              créer compte
            </a>
          </span>
        </div>
      </div>
    </main>
  );
};

export default Login;
