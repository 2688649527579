import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { APP_URL } from "../../../URL";

const ListBooking = () => {
  const [listBooking, SetListBooking] = useState("");
  const [listeReservation, SetListeReservation] = useState([]);
  const [idCli, setIdcli] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(atob(payloadBase64));
        setIdcli(payloadJSON.id_cli);
      } catch (error) {
        console.log("Erreur de décodage du token JWT", error);
      }
    }
  }, [token]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(APP_URL + "booking", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        SetListeReservation(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des réservations", error);
      }
    };

    fetchBookings();
  }, [token]);

  const handleArrowClick = (reservation) => {
    navigate("/Addbooking", { state: { reservation } }); // Use navigate
  };

  return (
    <>
      <Sidebar>
        <div className="container">
          <div className="table-responsive">
            <h1>List Booking</h1>

            <table className="table tabveh">
              <thead>
                <tr>
                  <th>Numero de client</th>
                  <th>Num réservation</th>
                  <th>ligne Maritime</th>
                  <th>destination</th>
                  <th>Type conteneur</th>
                  <th>Numéro de conteneur</th>
                  <th>Date de chargement</th>
                </tr>
              </thead>

              <tbody>
                {listeReservation.map((reservation) => (
                  <tr key={reservation.num_res}>
                    <td>CLIENT{reservation.id_cli}</td>
                    <td>BK{reservation.num_res}</td>
                    <td>{reservation.lignema}</td>
                    <td>{reservation.destination}</td>
                    <td>{reservation.type_conteneur}</td>
                    <td>{reservation.numero_conteneur}</td>
                    <td>
                      {new Date(reservation.date_charge).toLocaleDateString(
                        "fr-FR"
                      )}
                    </td>
                    <td>
                      <a
                        className="btn btn-success"
                        onClick={() => handleArrowClick(reservation)}
                      >
                        <i className="fa fa-arrow-right"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default ListBooking;
