import React, { useState, useEffect } from "react";
import Sidebar from "../../admin/Sidebar/Sidebar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { APP_URL } from "../../../URL";

const Shipping = () => {
  const [shippingData, setShippingData] = useState([]);
  const navigate = useNavigate();
  const [idCli, setIdCli] = useState("");
  const [role, setRole] = useState("");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(window.atob(payloadBase64));
        setIdCli(payloadJSON.clientId);
        setRole(payloadBase64.role);
      } catch (error) {
        console.log("Erreur de décodage du token JWT", error);
      }
    }
  }, [token]);

  useEffect(() => {
    // Fonction pour récupérer les données depuis l'API
    const fetchShippingData = async () => {
      try {
        const response = await axios.get(APP_URL + "Allshipping", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShippingData(response.data);
      } catch (error) {
        console.error("Error fetching shipping data:", error);
      }
    };

    // Appel de la fonction pour récupérer les données lors du chargement du composant
    fetchShippingData();
  }, [token]);

  const handleArrowClick = (shipping) => {
    // Assurez-vous que l'objet contient toutes les propriétés nécessaires
    const reservation = {
      id_book: shipping.id_book,
      numero_conteneur: shipping.numero_conteneur,
      id_shipping: shipping.id_shipping,
      // Ajoutez d'autres propriétés nécessaires ici
    };
    console.log(reservation);
    navigate("/info_ship", { state: { reservation } });
  };

  return (
    <Sidebar>
      <div className="container">
        <h2>Shipping Instructions</h2>
        <div className="table-responsive">
          <table className="table tabveh">
            <thead>
              <tr>
                <th>Numéro</th>
                <th>Ligne maritime</th>
                <th>Destination</th>
                <th>Type conteneur</th>
                <th>Numéro de conteneur</th>
                <th>Numéro de booking</th>
                <th>Date réservation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {shippingData.map((shipping) => (
                <tr key={shipping.id_shipping}>
                  <td>SHIP{shipping.id_shipping}</td>
                  <td>{shipping.lignema}</td>
                  <td>{shipping.destination}</td>
                  <td>{shipping.type_conteneur}</td>
                  <td>{shipping.numero_conteneur}</td>
                  <td>BK{shipping.id_book}</td>
                  <td>
                    {new Date(shipping.date_charge).toLocaleDateString(
                      "fr-FR"
                    )}
                  </td>
                  <td>
                    <a
                      className="btn btn-success"
                      onClick={() => handleArrowClick(shipping)}
                    >
                      <i className="fa fa-arrow-right"></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Sidebar>
  );
};

export default Shipping;
