import React from "react";
import NavVue from "./NavVue";
import about from "../../img/african-american-warehouse-woman-operator-holding-clipboard.jpg";
import bg from "../../img/4873152.jpg";
import FooterVue from "./FooterVue";
import team1 from "../../img/team.jpg";
import team2 from "../../img/team.jpg";
import team3 from "../../img/team.jpg";
import team4 from "../../img/team.jpg";

const About = () => {
  return (
    <>
      <nav
        className="fixed-top-navbar"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: 10,
        }}
      >
        <NavVue />
      </nav>

      {/* <!-- Page Header Start --> */}
      <div className="container-fluid page-header py-5 about-image">
        <div
          className="container py-5"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h1 className="display-3 text-white mb-3 animated slideInDown fw-bolder">
            A propos de nous
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Accueil
                </a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                A propos
              </li>
            </ol>
          </nav>
        </div>
      </div>

      {/* <!-- Page Header End --> */}

      {/* <!-- About Start --> */}
      <div
        className="container-fluid overflow-hidden py-5 px-lg-0"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100%", // Ajustez la hauteur selon vos besoins
        }}
      >
        <div className="container about py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0 wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div
                className="position-relative h-100"
                style={{ marginLeft: "14px" }}
              >
                <img
                  className="position-absolute img-fluid w-100 h-100 about-image"
                  src={about}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text wow fadeInUp"
              data-wow-delay="0.3s"
              style={{
                paddingLeft: "30px", // Augmenter l'espacement pour le texte
              }}
            >
              <h5 className="text-secondary text-uppercase mb-3">
                A propos de Nous
              </h5>
              <h1 className="mb-5">Transport Express & Logistique Rapide</h1>
              <p className="mb-5">
                Bienvenue chez{" "}
                <span className="fw-bold text-success">WATISEERAMARITIME</span>,
                votre expert en solutions de transport et de logistique rapides.
                Depuis notre création, nous avons fait de notre mission de
                fournir des services de transit de haute qualité, personnalisés
                et fiables pour répondre aux besoins urgents de nos clients.
              </p>
              <div className="row g-4 mb-5">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                  <i className="fa fa-globe fa-3x text-success mb-3"></i>
                  <h5>Couverture Mondiale</h5>
                  <p className="m-0">
                    Notre réseau étendu garantit des livraisons partout, en
                    toute sécurité et en temps opportun.
                  </p>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                  <i className="fa fa-shipping-fast fa-3x text-success mb-3"></i>
                  <h5>Livraison dans les délais</h5>
                  <p className="m-0">
                    Avec notre service de livraison express, vos marchandises
                    arrivent là où elles doivent aller, quand elles doivent y
                    être, sans compromettre la qualité ni la sécurité.
                  </p>
                </div>
              </div>
              <a
                href="/services"
                className="btn btn-success py-3 px-5"
                style={{
                  backgroundColor: "#28a745", // Couleur verte plus foncée pour le bouton
                  borderColor: "#28a745",
                  transition:
                    "background-color 0.3s ease, border-color 0.3s ease", // Transition pour le changement de couleur
                }}
              >
                Voir plus...
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- About End --> */}

      {/* pourquoi nous */}
      <div className="container- py-5 bg-light">
        <div className="container py-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="mb-4 text-success">POURQUOI NOUS CHOISIR?</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="feature-box p-4">
                <i className="fas fa-check-circle feature-icon"></i>
                <h5 className="mb-3">Expérience et Expertise</h5>
                <p>
                  Notre équipe compte des experts chevronnés dans le domaine du
                  transport et de la logistique.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="feature-box p-4">
                <i className="fas fa-truck-moving feature-icon"></i>
                <h5 className="mb-3">Service Rapide et Fiable</h5>
                <p>
                  Nous nous engagemons à livrer vos marchandises dans les délais
                  promis, sans compromettre la qualité.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="feature-box p-4">
                <i className="fas fa-lock feature-icon"></i>
                <h5 className="mb-3">Sécurité Maximale</h5>
                <p>
                  La sécurité de vos marchandises est notre priorité. Nous
                  utilisons des moyens de transport sécurisés et contrôlés.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="feature-box p-4">
                <i className="fas fa-mobile feature-icon"></i>
                <h5 className="mb-3">Service en ligne</h5>
                <p>
                  Possibilité de faire des réservations de conteneurs et de
                  parking depuis chez vous.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="feature-box p-4">
                <i className="fas fa-phone feature-icon"></i>
                <h5 className="mb-3">Support Exceptionnel</h5>
                <p>
                  Notre équipe de support client est disponible 24/7 pour
                  répondre à toutes vos questions et résoudre vos problèmes.
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="feature-box p-4">
                <i className="fas fa-users feature-icon"></i>
                <h5 className="mb-3">Equipe professionnelle</h5>
                <p>
                  WATISEERA met à votre disposition à tout temps et toute heure
                  une équipe d'expert à votre écoute.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* pourquoi nous end */}

      {/* <!-- Team Start --> */}
      <div className="container-xxl py-5">
        <div className="container py-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            {/* <h6 className="text-secondary text-uppercase">Notre Equipe</h6> */}
            <h1 className="mb-5">Nos Experts</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={team1} alt="" />
                </div>
                <h5 className="mb-0">Full Name</h5>
                <p>Designation</p>
                <div className="btn-slide mt-1">
                  <i className="fa fa-share"></i>
                  <span>
                    <a href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={team2} alt="" />
                </div>
                <h5 className="mb-0">Full Name</h5>
                <p>Designation</p>
                <div className="btn-slide mt-1">
                  <i className="fa fa-share"></i>
                  <span>
                    <a href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="team-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={team3} alt="" />
                </div>
                <h5 className="mb-0">Full Name</h5>
                <p>Designation</p>
                <div className="btn-slide mt-1">
                  <i className="fa fa-share"></i>
                  <span>
                    <a href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.9s"
            >
              <div className="team-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={team4} alt="" />
                </div>
                <h5 className="mb-0">Full Name</h5>
                <p>Designation</p>
                <div className="btn-slide mt-1">
                  <i className="fa fa-share"></i>
                  <span>
                    <a href="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Team End --> */}

      {/* contact */}
      <div className="container- py-5" style={{ backgroundColor: "#acc03b" }}>
        <div className="container py-5">
          <div className="row g-4">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="mb-4 text-light">CONTACTER NOUS!</h1>
              </div>
              <div>
                <p className="fw-bold">
                  Chez{" "}
                  <span className="fw-bold text-success">
                    WATISEERAMARITIME
                  </span>
                  , nous comprenons les complexités de la logistique et du
                  transport modernes.Notre équipe se consacre à à fournir des
                  solutions fiables et transparentes, adaptées à vos uniques.
                  Discutons ensemble de la manière dont nous pouvons améliorer
                  votre stratégie logistique.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="text-center">
                <i
                  className="fas fa-phone feature-icon mb-3"
                  style={{ fontSize: "3rem", color: "#1b702f" }}
                ></i>
                <p className="fw-bold fs-4">Nous joindre : +1 (438) 938-5616</p>
              </div>
              <div className="text-center">
                <i
                  className="fas fa-envelope feature-icon mb-3"
                  style={{ fontSize: "3rem", color: "#1b702f" }}
                ></i>
                <span>
                  <p className="fw-bold fs-4">
                    Email: contact@watiseeramaritime.com
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact */}

      <FooterVue />
    </>
  );
};

export default About;
