import React from "react";
import Logoboat from "../../img/logo Watiseeramaritime-02 1.png";

const Logo = ({ sidebarCollapsed }) => {
  return (
    <div className="logo">
      {sidebarCollapsed ? ( // Si le sidebar est réduit, afficher les lettres "WM"
        <div className="logo-text text-uppercase">WM</div>
      ) : (
        // Sinon, afficher le logo
        <div className="logo-icon">
          <img src={Logoboat} alt="Logo" width="150px" />
        </div>
      )}
    </div>
  );
};

export default Logo;
