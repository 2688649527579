import React from "react";

const FooterVue = () => {
  return (
    <div>
      {/* // Footer Start */}
      <div
        className="container-fluid footer wow fadeIn"
        data-wow-delay="0.1s"
        
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-4 col-md-6">
              <h4 className="text-dark mb-4">Addresse</h4>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3"></i>123 Street,
                montreal, CANADA
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3"></i>+1 (438) 938-5616
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3"></i>info@example.com
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-youtube"></i>
                </a>
                <a className="btn btn-outline-light btn-social" href="">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="text-dark mb-4">Services</h4>
              <a className="btn btn-link text-decoration-none" href="/chargementCont">
                Chargement Conteneur
              </a>
              <a className="btn btn-link text-decoration-none" href="/transportMari">
                Transport maritime
              </a>
              <a className="btn btn-link text-decoration-none" href="/transportTerres">
                Transport Terrestre
              </a>
             
              <a className="btn btn-link text-decoration-none" href="/Entreposage">
                Entreposage
              </a>
            </div>
            <div className="col-lg-4 col-md-6">
              <h4 className="text-dark mb-4">Liens Rapides</h4>
              <a className="btn btn-link text-decoration-none" href="/about">
                A propos
              </a>
              <a className="btn btn-link text-decoration-none" href="/contact">
                Contact
              </a>
              <a className="btn btn-link text-decoration-none" href="/services">
                Nos Services
              </a>
              <a className="btn btn-link text-decoration-none" href="/login">
                Se connecter
              </a>
              
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                &copy;{" "}
                <a className="border-bottom fw-bold" href="#">
                  WATISEERAMaritime
                </a>
                , All Right Reserved.
              </div>
              {/* <div className="col-md-6 text-center text-md-end">
                        Designed By <a className="border-bottom" href="https://htmlcodex.com">HTML Codex</a>
                        <br />Distributed By <a className="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</a>
                    </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* Footer End  */}
    </div>
  );
};

export default FooterVue;
