import React, { useState, useEffect } from "react";
import Sidebar from "../../admin/Sidebar/Sidebar";
import "./vehicule.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { APP_URL } from "../../../URL";

const Vehicule = () => {
  const [imm, setImmatriculation] = useState("");
  const [mode, setMode] = useState("");
  const [marque, setMarque] = useState("");
  const [annee, setAnnee] = useState("");
  const [nbre_cle, setNbr_cle] = useState("");
  const [couleur, setCouleur] = useState("");
  const [etat_carross, setEtat] = useState("");
  const [destination, setDestination] = useState("");
  const [poids, setPoids] = useState("");
  const [idCli, setIdCli] = useState("");
  const [role, setRole] = useState("");
  const token = localStorage.getItem("token");
  const [stockDisponible, setStockDisponible] = useState(0);

  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(window.atob(payloadBase64));
        setIdCli(payloadJSON.clientId);
        setRole(payloadJSON.role);
        console.log(role);
        console.log(idCli);
      } catch (error) {
        console.log("Erreur de décodage du token JWT", error);
      }
    }
  }, [token]);

  const [parkingDisponibile, setParkingDisponibile] = useState(0);

  useEffect(() => {
    axios
      .get(APP_URL + "parkingdisponible")
      .then((response) => {
        console.log("Response data:", response.data);
        // Si vous vous attendez à un seul résultat, prenez le premier élément du tableau
        if (response.data.length > 0) {
          setParkingDisponibile(response.data[0].parking_disponible);
        } else {
          console.error("Aucun espace disponible trouvé");
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération de l'espace disponible:",
          error.message
        );
      });
  }, []); // tableau de dépendances vide

  // liste_vehicule
  const [liste_vehicule, setListe_vehicule] = useState([]);
  const [editVehicule, setEditVehicule] = useState(null);
  const [showModal, setShowmodal] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (role === "admin") {
      axios
        .get(APP_URL + "allvehicule", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setListe_vehicule(response.data);
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération de tous les véhicules :",
            error.message
          );
        });
    } else if (role === "client") {
      axios
        .get(APP_URL + "vechicule", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setListe_vehicule(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // Redirection vers la page de connexion ou traitement de l'erreur d'authentification
            console.error("Token invalide ou expiré");
          } else {
            // Gestion d'autres erreurs
            console.error(
              "Erreur lors de la récupération des véhicules :",
              error.message
            );
          }
        });
    }
  }, [token, role]);

  const handleEdit = (vehicule) => {
    setEditVehicule(vehicule);
    setShowmodal(true);
  };

  const handleCloseModal = () => {
    setEditVehicule(null);
    setShowmodal(false);
  };

  const handleUpdate = () => {
    console.log("Updating vehicule with data:", editVehicule);
    axios
      .put(APP_URL + `vechicule/${editVehicule.id_vehicule}`, editVehicule, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Véhicule modifier avec succès!!");
        setShowmodal(false);
        axios
          .get(APP_URL + "allvehicule", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setListe_vehicule(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //suppression d'un véhicule
  const handleDelete = (id_vehicule) => {
    const confirmDelete = window.confirm(
      "Êtes vous sur de vouloir supprimer ce véhicule?"
    );
    if (confirmDelete) {
      axios
        .delete(APP_URL + `vechicule/${id_vehicule}`)
        .then((response) => {
          setListe_vehicule(
            liste_vehicule.filter(
              (vehicule) => vehicule.id_vehicule !== id_vehicule
            )
          );
          console.log("Vehicule supprimer avec succès");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const vehicule = {
      imm: imm,
      mode: mode,
      marque: marque,
      annee: annee,
      nbre_cle: nbre_cle,
      couleur: couleur,
      etat_carross: etat_carross,
      destination: destination,
      poids: poids,
      id_cli: idCli,
    };

    axios
      .post(APP_URL + "vechicule", vehicule, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        alert("Véhicule enregistrer avec succès!!");
        setImmatriculation("");
        setMode("");
        setMarque("");
        setAnnee("");
        setNbr_cle("");
        setCouleur("");
        setEtat("");
        setDestination("");
        setPoids("");
        setIdCli("");
        console.log(vehicule);

        // Rafraîchir la page après la mise à jour du statut
        window.location.reload();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setError("Immatriculation déjà existante");
        } else {
          console.log(error);
        }
      });
  };

  const updateStatusVehicle = (vehicleId, currentStatus) => {
    const newStatus = currentStatus === 0 ? 1 : 0; // Inverse le statut actuel

    // Appel à l'API pour mettre à jour le statut du véhicule
    axios
      .put(APP_URL + `vechicule/status/${vehicleId}`, { status: newStatus })
      .then((response) => {
        console.log(response.data.message);

        // Mise à jour du statut du véhicule localement
        setListe_vehicule((prevState) =>
          prevState.map((vehicule) =>
            vehicule.id_vehicule === vehicleId
              ? { ...vehicule, status: newStatus }
              : vehicule
          )
        );
        // Rafraîchir la page après la mise à jour du statut
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const libererVehicle = (vehicleId) => {
    axios
      .put(APP_URL + `vechicule/status/${vehicleId}`, { status: 2 })
      .then((response) => {
        console.log(response.data.message);
        setListe_vehicule((prevState) =>
          prevState.map((vehicule) =>
            vehicule.id_vehicule === vehicleId
              ? { ...vehicule, status: 2 }
              : vehicule
          )
        );
        setParkingDisponibile(parkingDisponibile + 1);
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const chargerVehicle = (vehicleId) => {
    axios
      .put(APP_URL + `vechicule/status/${vehicleId}`, { status: 3 })
      .then((response) => {
        console.log(response.data.message);
        setListe_vehicule((prevState) =>
          prevState.map((vehicule) =>
            vehicule.id_vehicule === vehicleId
              ? { ...vehicule, status: 3 }
              : vehicule
          )
        );
        setParkingDisponibile(parkingDisponibile + 1);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Sidebar>
      <div className="container">
        <div className="veh">
          <h2 className="mt-4">Enregistrement d'un véhicule</h2>
          <div className="alert alert-success">
            Espace disponible: {parkingDisponibile}
          </div>
          <form action="" className="form" onSubmit={handleSubmit}>
            {error && <div className="alert alert-danger">{error}</div>}
            <input type="hidden" name="id_cli" value={idCli} />
            <div className="row">
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="imm"
                  id="imm"
                  placeholder="Immatriculation"
                  value={imm}
                  onChange={(e) => setImmatriculation(e.target.value)}
                />
              </div>
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="mode"
                  id="mode"
                  placeholder="Modèle"
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="marque"
                  id="marque"
                  placeholder="Marque"
                  value={marque}
                  onChange={(e) => setMarque(e.target.value)}
                />
              </div>
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="annee"
                  id="annee"
                  placeholder="Année de fabrication"
                  value={annee}
                  onChange={(e) => setAnnee(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="nbre_cle"
                  id="nbre_cle"
                  placeholder="Nombre de clé"
                  value={nbre_cle}
                  onChange={(e) => setNbr_cle(e.target.value)}
                />
              </div>
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="couleur"
                  id="couleur"
                  placeholder="Couleur"
                  value={couleur}
                  onChange={(e) => setCouleur(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="etat_carross"
                  id="etat_carross"
                  placeholder="Etat"
                  value={etat_carross}
                  onChange={(e) => setEtat(e.target.value)}
                />
              </div>
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="destination"
                  id="destination"
                  placeholder="Destination"
                  value={destination}
                  onChange={(e) => setDestination(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="poids"
                  id="poids"
                  placeholder="Poids"
                  value={poids}
                  onChange={(e) => setPoids(e.target.value)}
                />
              </div>
            </div>
            <button
              className="btn mt-5 col-sm-4"
              type="submit"
              style={{ backgroundColor: "#198754" }}
            >
              Ajouter votre véhicule
            </button>
          </form>
        </div>

        <div className="listveh table-responsive">
          <h2>Liste des véhicules</h2>
          {liste_vehicule ? (
            <table className="table tabveh text-center">
              <thead>
                <tr>
                  {role === "admin" && <th>Client</th>}
                  <th>Immatriculation</th>
                  <th>Modèle</th>
                  <th>Marque</th>
                  <th>Année</th>
                  <th>nbre clé</th>
                  <th>Couleur</th>
                  <th>État</th>
                  <th>Destination</th>
                  <th>Poids</th>
                  <th>Action</th>
                  {role === "admin" && <th>Status</th>}
                </tr>
              </thead>
              <tbody>
                {liste_vehicule.map((vehicule) => (
                  <tr key={vehicule.id_vehicule}>
                    {role === "admin" && (
                      <td>
                        {vehicule.nom_utilisateur}&nbsp;{vehicule.prenom_user}
                      </td>
                    )}
                    <td>{vehicule.imm}</td>
                    <td>{vehicule.mode}</td>
                    <td>{vehicule.marque}</td>
                    <td>{vehicule.annee}</td>
                    <td>{vehicule.nbre_cle}</td>
                    <td>{vehicule.couleur}</td>
                    <td>{vehicule.etat_carross}</td>
                    <td>{vehicule.destination}</td>
                    <td>{vehicule.poids} Kg</td>
                    <td>
                      <div className="btn-group" role="group">
                        <button
                          className="btn btn-edit btn-sm text-success"
                          onClick={() => handleEdit(vehicule)}
                        >
                          <i className="fa fa-edit"></i>
                        </button>
                        <button
                          className="btn btn-edit btn-sm text-danger"
                          onClick={() => handleDelete(vehicule.id_vehicule)}
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                    {role === "admin" && (
                      <td>
                        <div className="btn-group" role="group">
                          <button
                            className="btn btn-sm btn-success"
                            onClick={() =>
                              updateStatusVehicle(
                                vehicule.id_vehicule,
                                vehicule.status
                              )
                            }
                            disabled={vehicule.status !== 0}
                          >
                            <i className="fa fa-check"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-warning"
                            onClick={() => libererVehicle(vehicule.id_vehicule)}
                            disabled={vehicule.status !== 1}
                          >
                            <i className="fa fa-unlock"></i>
                          </button>
                          <button
                            className="btn btn-sm btn-info"
                            onClick={() => chargerVehicle(vehicule.id_vehicule)}
                            disabled={vehicule.status !== 1}
                          >
                            <i className="fa fa-truck"></i>
                          </button>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1>Chargement de la liste de véhicule....</h1>
          )}
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Modifier le client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicNom">
                <Form.Label>Immatriculation</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.imm : ""}
                  onChange={(e) =>
                    setEditVehicule({
                      ...editVehicule,
                      imm: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicPrenom">
                <Form.Label>Modèle</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.mode : ""}
                  onChange={(e) =>
                    setEditVehicule({ ...editVehicule, mode: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Marque</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.marque : ""}
                  onChange={(e) =>
                    setEditVehicule({ ...editVehicule, marque: e.target.value })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicAdresse">
                <Form.Label>Année</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.annee : ""}
                  onChange={(e) =>
                    setEditVehicule({
                      ...editVehicule,
                      annee: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicTel">
                <Form.Label>Nombre clé</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.nbre_cle : ""}
                  onChange={(e) =>
                    setEditVehicule({
                      ...editVehicule,
                      nbre_cle: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicRole">
                <Form.Label>Couleur</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.couleur : ""}
                  onChange={(e) =>
                    setEditVehicule({
                      ...editVehicule,
                      couleur: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formBasicEtat">
                <Form.Label>Etat</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.etat_carross : ""}
                  onChange={(e) =>
                    setEditVehicule({
                      ...editVehicule,
                      etat_carross: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formBasicDestination">
                <Form.Label>Destination</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.destination : ""}
                  onChange={(e) =>
                    setEditVehicule({
                      ...editVehicule,
                      destination: e.target.value,
                    })
                  }
                />
              </Form.Group>

              <Form.Group controlId="formBasicPoid">
                <Form.Label>Poids</Form.Label>
                <Form.Control
                  type="text"
                  value={editVehicule ? editVehicule.poids : ""}
                  onChange={(e) =>
                    setEditVehicule({ ...editVehicule, poids: e.target.value })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fermer
            </Button>
            <Button variant="success" onClick={handleUpdate}>
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Sidebar>
  );
};

export default Vehicule;
