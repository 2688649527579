import React, { useState, useEffect } from "react";
import Sidebar from "../../admin/Sidebar/Sidebar";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { APP_URL } from "../../../URL";

const BookingsAdmin = () => {
  const [numeroCont, setNumeroCont] = useState("");

  const location = useLocation();
  const reservation = location.state?.reservation;
  const [Idclient, setIdClient] = useState("");

  //récupérer l'id du client depuis la réservation
  useEffect(() => {
    if (reservation) {
      setIdClient(`CLIENT${reservation.id_cli}`);
    }
  }, [reservation]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const reservationId = reservation.num_res;
    const conteneurNum = numeroCont;

    const bookingData = {
      num_res: reservationId,
      numero_conteneur: conteneurNum,
      id_cli: Idclient,
    };
    axios
      .post(APP_URL + "booking/addContainerNumber", bookingData)
      .then((response) => {
        alert("numero conteneur enregistrer avec succès!");
        setNumeroCont("");
        window.location = "/ListBooking";
      })
      .catch((error) => {
        console.log("Erreur lors de l'enregistrement", error);
      });
  };

  return (
    <>
      <Sidebar>
        <div className="container">
          <div className="card">
            <div className="card-header">
              Insérer Numéro de books et de conteneur
            </div>
            <div className="card-body">
              <form
                action=""
                className="form-horizontal"
                onSubmit={handleSubmit}
              >
                <div className="form-group pb-4">
                  <label htmlFor="Numerorese" className="fw-bold">
                    Numéro de Réservation
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={reservation ? `BK${reservation.num_res}` : ""}
                    disabled
                  />
                </div>

                <div className="form-group pb-4">
                  <label htmlFor="NumeroCont" className="fw-bold">
                    Numéro de Conteneur
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={numeroCont}
                    onChange={(e) => setNumeroCont(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group pb-4">
                  <label htmlFor="numeroCli" className="fw-bold">
                    Numéro du client
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={Idclient}
                    disabled
                  />
                </div>

                <button className="btn btn-primary" type="submit">
                  <i className="fas fa-plus"></i>Ajouter numéro du conteneur
                </button>
              </form>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default BookingsAdmin;
