import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/dashboard.css";
import Sidebar from "./Sidebar/Sidebar";

const Dashboard = () => {
  const [nom, setNom] = useState("");
  const [clientRole, setClientRole] = useState("");

  const token = localStorage.getItem("token");
  // console.log(token)
  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(atob(payloadBase64));
        setNom(payloadJSON.nom);
        setClientRole(payloadJSON.role);
      } catch (error) {
        console.log("Erreur de décodage du token JWT", error);
      }
    }
  }, [token]);

  return (
    <Sidebar>
      <div className="dashboard">
        <div
          className="alert dash custom-alert"
          style={{ backgroundColor: "#008000" }}
        >
          <h2 className="text-white">Hello {nom} &#128075;</h2>
        </div>
        <h3>Dashboad</h3>
      </div>
    </Sidebar>
  );
};

export default Dashboard;
