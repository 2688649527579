import React, { useState } from "react";
import { Children } from "react"; // Importer React.Children
import { Button, Layout, theme } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import "../../css/Sidebar.css";
import Logo from "./Logo";
import MenuList from "./MenuList";
import ToggleThemeButton from "./ToggleThemeButton";

const { Header, Sider, Content } = Layout;

function Sidebar({ children }) {
  const [darkTheme, setDarkTheme] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleTheme = () => {
    setDarkTheme(!darkTheme);
  };

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const primaryColor = "#223457";

  return (
    <Layout>
      <Sider
        collapsed={collapsed}
        collapsible
        trigger={null}
        theme={darkTheme ? "dark" : "light"}
        className="sidebar"
        style={{
          backgroundColor: darkTheme ? primaryColor : "#fff",
          color: darkTheme ? "#fff" : "#000",
        }}
      >
        <Logo sidebarCollapsed={sidebarCollapsed} />
        <MenuList darkTheme={darkTheme} />
        <div className="toggle-theme-btn">
          <ToggleThemeButton darkTheme={darkTheme} toggleTheme={toggleTheme} />
        </div>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
          <Button
            className="toggle"
            onClick={() => {
              setCollapsed(!collapsed);
              setSidebarCollapsed(!sidebarCollapsed); // Mettre à jour l'état du sidebar réduit
            }}
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          ></Button>
        </Header>
        <Content className="content">{Children.toArray(children)}</Content>{" "}
        {/* Rendu des enfants via Children.toArray */}
      </Layout>
    </Layout>
  );
}

export default Sidebar;
