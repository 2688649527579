import React from "react";
import NavVue from "./NavVue";
import FooterVue from "./FooterVue";
import service1 from "../../img/service-1.jpg";
import service2 from "../../img/service-2.jpg";
import service3 from "../../img/service-3.jpg";
import service4 from "../../img/service-4.jpg";
import service5 from "../../img/service-5.jpg";
import service6 from "../../img/service-6.jpg";

const Services = () => {
  return (
    <div>
      <NavVue />
      {/* <!-- Page Header Start --> */}
      <div className="container-fluid page-header py-5 service-image">
        <div
          className="container py-5"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h1 className="display-3 text-white mb-3 animated slideInDown fw-bolder">
            Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="#">
                  Accueil
                </a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Service
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      <div className="container- py-5 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="fw-bold" style={{ color: "#acc03b" }}>
                Nos solutions à vos besoins
              </h3>
              <p className="fw-bold fs-3">
                Fournir les meilleurs services de transit mondiaux.
              </p>
            </div>
            <div className="col-lg-6">
              <p className="text-end fw-bold">
                Explorons ensemble comment nous pouvons optimiser vos opérations
                et maximiser votre productivité. Découvrez nos services et
                découvrez pourquoi nous sommes le choix privilégié pour les
                entreprises de toutes tailles.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Service Start --> */}
      <div className="container-">
        <div className="container py-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="text-secondary text-uppercase">Nos Services</h6>
            <h1 className="mb-5 text-uppercase">Explorez nos Services</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={service5} alt="" />
                </div>
                <h4 className="mb-3">RESERVATION DE CONTENEUR</h4>
                <p>
                  WATISEERA vous permet également de faire la réservation de
                  conteneur chez-nous ou chez nos differents partenaires pour un
                  transit plus rassurant.
                </p>
                <a className="btn-slide mt-2" href="">
                  <i className="fa fa-arrow-right"></i>
                  <span>Détails</span>
                </a>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={service2} alt="" />
                </div>
                <h4 className="mb-3">TRANSPORT MARITIME</h4>
                <p>
                  En tant que leader Transitaire MontRealais, WATISEERA excelle
                  dans la fourniture de transports sur mesure
                </p>
                <a className="btn-slide mt-2" href="">
                  <i className="fa fa-arrow-right"></i>
                  <span>Détails</span>
                </a>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={service4} alt="" />
                </div>
                <h4 className="mb-3">ENTREPOSAGE</h4>
                <p>
                  WATISEERAMaritime dispose de plusieurs magasins de qualite
                  pour le stockage de vos diverses marchandises enregistrees
                  dans l'attente d'un eventuel chargement
                </p>
                <a className="btn-slide mt-2" href="">
                  <i className="fa fa-arrow-right"></i>
                  <span>Détails</span>
                </a>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={service3} alt="" />
                </div>
                <h4 className="mb-3">TRANSPORT TERRESTRE</h4>
                <p>
                  Compter parmi les leaders du transport terrestre mondial,
                  WATISEERA excelle dans la fourniture de transports sur mesure.
                </p>
                <a className="btn-slide mt-2" href="">
                  <i className="fa fa-arrow-right"></i>
                  <span>Détails</span>
                </a>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={service5} alt="" />
                </div>
                <h4 className="mb-3">RESERVATION DE CONTENEUR</h4>
                <p>
                  WATISEERA vous permet également de faire la réservation de
                  conteneur chez-nous ou chez nos differents partenaires pour un
                  transit plus rassurant.
                </p>
                <a className="btn-slide mt-2" href="">
                  <i className="fa fa-arrow-right"></i>
                  <span>Détails</span>
                </a>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-4 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item p-4">
                <div className="overflow-hidden mb-4">
                  <img className="img-fluid" src={service1} alt="" />
                </div>
                <h4 className="mb-3">TRANSPORT AERIEN</h4>
                <p>
                  Compter parmi les leaders du transport aerien mondial,
                  Sorotransit excelle dans la fourniture de transports sur
                  mesure.
                </p>
                <a className="btn-slide mt-2" href="">
                  <i className="fa fa-arrow-right"></i>
                  <span>Details</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      {/* contact */}
      <div className="container- py-5" style={{ backgroundColor: "#acc03b" }}>
        <div className="container py-5">
          <div className="row g-4">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="wow fadeInUp" data-wow-delay="0.1s">
                <h1 className="mb-4 text-light">CONTACTER NOUS!</h1>
              </div>
              <div>
                <p className="fw-bold">
                  Chez{" "}
                  <span className="fw-bold text-success">
                    WATISEERAMARITIME
                  </span>
                  , nous comprenons les complexités de la logistique et du
                  transport modernes.Notre équipe se consacre à à fournir des
                  solutions fiables et transparentes, adaptées à vos uniques.
                  Discutons ensemble de la manière dont nous pouvons améliorer
                  votre stratégie logistique.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="text-center">
                <i
                  className="fas fa-phone feature-icon mb-3"
                  style={{ fontSize: "3rem", color: "#1b702f" }}
                ></i>
                <p className="fw-bold fs-4">Nous joindre : +1 (438) 938-5616</p>
              </div>
              <div className="text-center">
                <i
                  className="fas fa-envelope feature-icon mb-3"
                  style={{ fontSize: "3rem", color: "#1b702f" }}
                ></i>
                <span>
                  <p className="fw-bold fs-4">
                    Email: contact@watiseeramaritime.com
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* contact */}

      <FooterVue />
    </div>
  );
};

export default Services;
