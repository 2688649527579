import React, { useState, useEffect } from "react";
import Sidebar from "../../admin/Sidebar/Sidebar";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { APP_URL } from "../../../URL";
import './palette.css'

const Palette = () => {
  const [description, setDescription] = useState("");
  const [typePalette, setTypePalette] = useState("");
  const [idCli, setIdCli] = useState("");
  const [role, setRole] = useState("");
  const token = localStorage.getItem("token");
  const [paletteDisponibile, setPaletteDisponibile] = useState(0);

  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(window.atob(payloadBase64));
        setIdCli(payloadJSON.clientId);
        setRole(payloadJSON.role);
      } catch (error) {
        console.log("Erreur de décodage du token JWT", error);
      }
    }
  }, [token]);

  useEffect(() => {
    axios
      .get(APP_URL + "palettedisponible")
      .then((response) => {
        console.log("Response data:", response.data);
        // Si vous vous attendez à un seul résultat, prenez le premier élément du tableau
        if (response.data.length > 0) {
          setPaletteDisponibile(response.data[0].palette_disponible);
        } else {
          console.error("Aucun espace disponible trouvé");
        }
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération de l'espace disponible:",
          error.message
        );
      });
  }, []); // tableau de dépendances vide

  // Liste des palettes
  const [listePalette, setListePalette] = useState([]);
  const [editPalette, setEditPalette] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (role === "admin") {
      axios
        .get(APP_URL + "allPalette", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setListePalette(response.data);
        })
        .catch((error) => {
          console.error(
            "Erreur lors de la récupération de toutes les palettes :",
            error.message
          );
        });
    } else if (role === "client") {
      axios
        .get(APP_URL + "palettes", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setListePalette(response.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // Redirection vers la page de connexion ou traitement de l'erreur d'authentification
            console.error("Token invalide ou expiré");
          } else {
            // Gestion d'autres erreurs
            console.error(
              "Erreur lors de la récupération des palettes :",
              error.message
            );
          }
        });
    }
  }, [token, role]);

  const handleEdit = (palette) => {
    setEditPalette(palette);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditPalette(null);
    setShowModal(false);
  };

  const handleUpdate = () => {
    axios
      .put(APP_URL + `palette/${editPalette.id_pal}`, editPalette, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Palette modifiée avec succès!!");
        setShowModal(false);
        axios
          .get(APP_URL + "allPalette", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            setListePalette(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDelete = (id_pal) => {
    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cette palette?"
    );
    if (confirmDelete) {
      axios
        .delete(APP_URL + `palette/${id_pal}`)
        .then((response) => {
          setListePalette(
            listePalette.filter((palette) => palette.id_pal !== id_pal)
          );
          console.log("Palette supprimée avec succès");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const palette = {
      description: description,
      type_palette: typePalette,
      id_cli: idCli,
    };

    axios
      .post(APP_URL + "Addpalette", palette, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        alert("Palette enregistrée avec succès!!");
        setDescription("");
        setTypePalette("");
        setIdCli("");
        console.log(palette);

        // Rafraîchir la page après l'ajout de la palette
        window.location.reload();
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setError("Erreur lors de l'enregistrement de la palette");
        } else {
          console.log(error);
        }
      });
  };

  // changer status de la palette
  const updateStatusPalette = (paletteId, currentStatus) => {
    const newStatus = currentStatus === 0 ? 1 : 0; // Inverse le statut actuel

    // Appel à l'API pour mettre à jour le statut de la palette
    axios
      .put(APP_URL + `palette/status/${paletteId}`, { status: newStatus })
      .then((response) => {
        console.log(response.data.message);

        // Mise à jour du statut de la palette localement
        setListePalette((prevState) =>
          prevState.map((palette) =>
            palette.id_pal === paletteId
              ? { ...palette, status: newStatus }
              : palette
          )
        );
        // Rafraîchir la page après la mise à jour du statut
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //bouton libérer
  const libererPalette = (paletteId) => {
    axios
      .put(APP_URL + `palette/status/${paletteId}`, { status: 2 })
      .then((response) => {
        console.log(response.data.message);
        setListePalette((prevState) =>
          prevState.map((palette) =>
            palette.id_pal === paletteId ? { ...palette, status: 2 } : palette
          )
        );
        setPaletteDisponibile(paletteDisponibile + 1);
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //bouton charger la palette
  const chargerpalette = (paletteId) => {
    axios
      .put(APP_URL + `palette/status/${paletteId}`, { status: 3 })
      .then((response) => {
        console.log(response.data.message);
        setListePalette((prevState) =>
          prevState.map((palette) =>
            palette.id_pal === paletteId ? { ...palette, status: 3 } : palette
          )
        );
        setPaletteDisponibile(paletteDisponibile + 1);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Sidebar>
      <div className="container">
        <div className="palette">
          <h2 className="mt-4">Enregistrement d'une palette</h2>
          <div className="alert alert-success">
            Palette disponible: {paletteDisponibile}
          </div>
          <form className="form" onSubmit={handleSubmit}>
            {error && <div className="alert alert-danger">{error}</div>}
            <input type="hidden" name="id_cli" value={idCli} />
            <div className="row">
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-sm-6">
                <input
                  required
                  className="inputs"
                  type="text"
                  name="type_palette"
                  id="type_palette"
                  placeholder="Type de palette"
                  value={typePalette}
                  onChange={(e) => setTypePalette(e.target.value)}
                />
              </div>
            </div>

            <button
              className="btn mt-5 col-sm-4"
              type="submit"
              style={{ backgroundColor: "#198754" }}
            >
              Ajouter votre palette
            </button>
          </form>
        </div>

        <div className="listpalette table-responsive">
          <h2>Liste des palettes</h2>
          {listePalette ? (
            <table className="table tabpalette text-center">
            <thead>
              <tr>
                {role === "admin" && <th>Client</th>}
                <th>Description</th>
                <th>Type de palette</th>
                <th>Action</th>
                {role === "admin" && <th>Status</th>}
              </tr>
            </thead>
            <tbody>
              {listePalette.map((palette) => (
                <tr key={palette.id_pal}>
                  {role === "admin" && (
                    <td>
                      {palette.nom_client} {palette.prenom_client}
                    </td>
                  )}
                  <td>{palette.description}</td>
                  <td>{palette.type_palette}</td>
                  <td>
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-edit btn-sm text-success"
                        onClick={() => handleEdit(palette)}
                      >
                        <i className="fa fa-edit"></i>
                      </button>
                      <button
                        className="btn btn-edit btn-sm text-danger"
                        onClick={() => handleDelete(palette.id_pal)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                  {role === "admin" && (
                    <td>
                      <div className="btn-group" role="group">
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() =>
                            updateStatusPalette(palette.id_pal, palette.status_palette)
                          }
                          disabled={palette.status_palette !== 0}
                        >
                          <i className="fa fa-check"></i>
                        </button>
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={() => libererPalette(palette.id_pal)}
                          disabled={palette.status_palette !== 1}
                        >
                          <i className="fa fa-unlock"></i>
                        </button>
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() => chargerpalette(palette.id_pal)}
                          disabled={palette.status_palette !== 1}
                        >
                          <i className="fa fa-truck"></i>
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          
          ) : (
            <h1>Chargement de la liste des palettes....</h1>
          )}
        </div>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Modifier la palette</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formBasicDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  value={editPalette ? editPalette.description : ""}
                  onChange={(e) =>
                    setEditPalette({
                      ...editPalette,
                      description: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="formBasicTypePalette">
                <Form.Label>Type de palette</Form.Label>
                <Form.Control
                  type="text"
                  value={editPalette ? editPalette.type_palette : ""}
                  onChange={(e) =>
                    setEditPalette({
                      ...editPalette,
                      type_palette: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Button variant="secondary" onClick={handleCloseModal}>
                Annuler
              </Button>
              <Button variant="primary" onClick={handleUpdate}>
                Mettre à jour
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </Sidebar>
  );
};

export default Palette;
