import React, { useState } from "react";
import NavVue from "./structure/NavVue";
import FooterVue from "./structure/FooterVue";
import axios from "axios";
import { APP_URL } from "../../URL";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(APP_URL + "sendmail", formData);
      alert("Email envoyé avec succès !");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email :", error);
      alert("Une erreur est survenue lors de l'envoi de l'email.");
    }
  };

  return (
    <div>
      <NavVue />
      {/* <!-- Page Header Start --> */}
      <div className="container-fluid page-header py-5 contact-image">
        <div
          className="container py-5"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h1 className="display-3 text-white mb-3 animated slideInDown fw-bolder">
            Contact
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a className="text-white" href="/accueil">
                  Accueil
                </a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                Contact
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* <!-- Page Header End --> */}

      {/* <!-- Contact Start --> */}
      <div className="container overflow-hidden py-5 px-lg-0">
        <div className="container contact-page py-5 px-lg-0">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-md-6 contact-form wow fadeIn"
              data-wow-delay="0.1s"
            >
              <h6 className="text-secondary text-uppercase">Contact</h6>
              <h1 className="mb-4">Contactez-nous pour toute question</h1>

              <div className="bg-light p-4">
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Votre nom"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="name">Nom</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Votre email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="email">Email</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="subject"
                          placeholder="Sujet"
                          value={formData.subject}
                          onChange={handleChange}
                          required
                        />
                        <label htmlFor="subject">Sujet</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Laissez votre message ici"
                          id="message"
                          style={{ height: "100px" }}
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                        <label htmlFor="message">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button
                        className="btn btn-success w-100 py-3"
                        type="submit"
                      >
                        Envoyer un Message
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div
              className="col-md-6 pe-lg-0 wow fadeInRight"
              data-wow-delay="0.1s"
            >
              <div className="position-relative h-100">
                <iframe
                  className="position-absolute w-100 h-100"
                  style={{ objectFit: "cover" }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                  frameBorder="0"
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Contact End --> */}

      {/* Contact */}
      <div className="container- py-5" style={{ backgroundColor: "#c8f3c3" }}>
        <div className="container py-5">
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <h1 className="mb-4 text-light">CONTACTEZ-NOUS !</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div>
                <p className="fw-bold">
                  Chez{" "}
                  <span className="fw-bold text-success">
                    WATISEERAMARITIME
                  </span>
                  , nous comprenons les complexités de la logistique et du
                  transport modernes. Notre équipe se consacre à fournir des
                  solutions fiables et transparentes, adaptées à vos besoins
                  uniques. Discutons ensemble de la manière dont nous pouvons
                  améliorer votre stratégie logistique.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="text-center">
                <i
                  className="fas fa-phone feature-icon mb-3"
                  style={{ fontSize: "3rem", color: "#1b702f" }}
                ></i>
                <p className="fw-bold fs-4">Nous joindre : +1 (438) 938-5616</p>
              </div>
              <div className="text-center">
                <i
                  className="fas fa-envelope feature-icon mb-3"
                  style={{ fontSize: "3rem", color: "#1b702f" }}
                ></i>
                <span>
                  <p className="fw-bold fs-4">
                    Email: contact@watiseeramaritime.com
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact */}

      <FooterVue />
    </div>
  );
};

export default Contact;
