import React, { useState } from "react";
import axios from "axios";
import { APP_URL } from "../../../URL";

const MotDePasseOublie = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!email) {
      setError("Veuillez saisir votre adresse e-mail");
      return;
    }

    axios
      .post(`${APP_URL}forgot-password`, { email })
      .then((response) => {
        console.log(response.data);
        alert("Mail de réinitialisation envoyé");
        // Redirigez l'utilisateur après l'envoi du mail si nécessaire
      })
      .catch((error) => {
        console.error(error);
        setError("Une erreur s'est produite. Veuillez réessayer plus tard.");
      });
  };

  return (
    <div>
      <div className="panel-container" style={panelContainerStyle}>
        <h2 style={titleStyle}>Mot de Passe Oublié</h2>
        <form onSubmit={handleSubmit} style={formStyle}>
          <div style={inputContainerStyle}>
            <label style={labelStyle}>Adresse E-mail :</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={inputStyle}
            />
          </div>
          {error && <div style={errorStyle}>{error}</div>}
          <div style={buttonContainerStyle}>
            <button type="submit" style={buttonStyle}>
              Envoyer
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Styles CSS inline
const panelContainerStyle = {
  maxWidth: "400px",
  margin: "auto",
  marginTop: "50px",
  padding: "20px",
  border: "1px solid #ccc",
  borderRadius: "5px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
};

const titleStyle = {
  textAlign: "center",
  fontSize: "24px",
  marginBottom: "20px",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const inputContainerStyle = {
  marginBottom: "20px",
};

const labelStyle = {
  display: "block",
  marginBottom: "5px",
};

const inputStyle = {
  width: "100%",
  padding: "8px",
  fontSize: "16px",
  borderRadius: "5px",
  border: "1px solid #ccc",
};

const errorStyle = {
  color: "red",
  marginBottom: "10px",
};

const buttonContainerStyle = {
  textAlign: "center",
};

const buttonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  backgroundColor: "#007bff",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default MotDePasseOublie;
