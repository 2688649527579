import axios from "axios";
import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";

const Espace = () => {
  const [palette_total, setPaletteTotal] = useState("");
  const [palette_occupe, setPaletteOccupe] = useState(0);
  const [parkingTotal, setParkingTotal] = useState("");
  const [parkingOccupe, setParkingOccupe] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();

    const espace = {
      plaquete_total: palette_total,
      plaquete_occupe: palette_occupe,
      parking_occupe: parkingOccupe,
      parking_total: parkingTotal,
    };

    axios
      .put(`http://localhost:8000/api/espace/{3}`, espace)
      .then((response) => {
        alert("espace créer avec succès");
        setPaletteTotal("");
        setParkingTotal("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Sidebar>
        <div className="container">
          <div className="mt-5">
            <div className="card-header"><h3>Création des espaces</h3></div>
            <div className="card-body">
              <form action="" className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="number"
                    className="input"
                    name="plaquete_total"
                    id="plaquete_total"
                    placeholder="Palette total"
                    required
                    value={palette_total}
                    onChange={(e) =>setPaletteTotal(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="number"
                    className="input"
                    name="parking_total"
                    id="parking_total"
                    placeholder="Parking total"
                    value={parkingTotal}
                    onChange={(e) =>setParkingTotal(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-success mt-4">Update</button>
              </form>
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default Espace;
