import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { APP_URL } from "../../../URL";

const ResetMotDePasse = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(""); // State to handle success message

  const { token } = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    axios
      .post(`${APP_URL}reset-password`, {
        token,
        newPassword: password,
        confirmPassword: confirmPassword,
      })
      .then((response) => {
        console.log(response.data);
        setSuccess("Mot de passe réinitialisé avec succès!!");
        setTimeout(() => {
          window.location = "/login";
        }, 3000);
        // Redirigez l'utilisateur après la réinitialisation du mot de passe si nécessaire
      })
      .catch((error) => {
        console.error(error);
        setError(
          error.response?.data ||
            "Une erreur s'est produite. Veuillez réessayer plus tard."
        );
      });
  };

  return (
    <div>
      <div className="panel-container" style={panelContainerStyle}>
        <h2 style={titleStyle}>Réinitialiser le Mot de Passe</h2>
        <form onSubmit={handleSubmit} style={formStyle}>
          <div style={inputContainerStyle}>
            <label style={labelStyle}>Nouveau Mot de Passe :</label>
            <input
              type="password"
              name="newPassword"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={inputStyle}
            />
          </div>
          <div style={inputContainerStyle}>
            <label style={labelStyle}>
              Confirmer le Nouveau Mot de Passe :
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={inputStyle}
            />
          </div>
          {error && <div style={errorStyle}>{error}</div>}
          {success && <div style={successStyle}>{success}</div>}
          <div style={buttonContainerStyle}>
            <button type="submit" style={buttonStyle}>
              Réinitialiser le Mot de Passe
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Styles CSS inline
const panelContainerStyle = {
  maxWidth: "400px",
  margin: "auto",
  marginTop: "50px",
  padding: "20px",
  border: "1px solid #ccc",
  borderRadius: "5px",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
};

const titleStyle = {
  textAlign: "center",
  fontSize: "24px",
  marginBottom: "20px",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const inputContainerStyle = {
  marginBottom: "20px",
};

const labelStyle = {
  display: "block",
  marginBottom: "5px",
};

const inputStyle = {
  width: "100%",
  padding: "8px",
  fontSize: "16px",
  borderRadius: "5px",
  border: "1px solid #ccc",
};

const errorStyle = {
  color: "red",
  marginBottom: "10px",
};

const successStyle = {
  color: "green",
  marginBottom: "10px",
};


const buttonContainerStyle = {
  textAlign: "center",
};

const buttonStyle = {
  padding: "10px 20px",
  fontSize: "16px",
  backgroundColor: "#007bff",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

export default ResetMotDePasse;
