import React, { useState, useEffect } from "react";
import Sidebar from "../../admin/Sidebar/Sidebar";
import "./booking.css";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { APP_URL } from "../../../URL";

const Booking = () => {
  const [numeroIdent, setNumeroIdent] = useState("");
  const [lignema, setLignema] = useState("");
  const [destination, setDestination] = useState("");
  const [type_conteneur, setTypeConteneur] = useState("");
  const [date_charge, setDateChargement] = useState("");
  const [message, setMessage] = useState("");

  const [listeReservation, SetListeReservation] = useState([]);
  const [editBooking, setEditBooking] = useState(null);
  const [showModal, setShowmodal] = useState(false);
  const token = localStorage.getItem("token");
  const [idCLi, setIDcli] = useState("");

  //récupérer le token
  useEffect(() => {
    if (token) {
      try {
        const payloadBase64 = token.split(".")[1];
        const payloadJSON = JSON.parse(window.atob(payloadBase64));
        setIDcli(payloadJSON.clientId);
        console.log(idCLi);
      } catch (error) {
        console.log(error.message);
      }
    }
  }, [token]);

  //liste des réservations
  useEffect(() => {
    axios
      .get(APP_URL + "reservation", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        SetListeReservation(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  // Update booking
  const handleEdit = (book) => {
    setEditBooking({
      ...book,
      date_charge: new Date(book.date_charge).toISOString().split("T")[0],
    });
    setShowmodal(true);
  };

  //fermeture du modal
  const handleCloseModal = () => {
    setEditBooking(null);
    setShowmodal(false);
  };

  //mise à jour de réservation
  const handleUpdate = () => {
    axios
      .put(APP_URL + `reservation/${editBooking.num_res}`, editBooking, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data.message);
        setShowmodal(false);
        axios
          .get(APP_URL + "reservation", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            SetListeReservation(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //enregistrement d'une réservation
  const handleSubmit = async (e) => {
    e.preventDefault();

    //vérifier si l'id du client est présent
    if (!idCLi) {
      setMessage("ID client non défini");
      return;
    }

    const reservation = {
      id_cli: idCLi,
      numeroIdent,
      lignema,
      destination,
      type_conteneur,
      date_charge,
    };

    axios
      .post(APP_URL + "reservation", reservation)
      .then((response) => {
        alert("Réservertion effectuer avec succès");
        setIDcli("");
        setNumeroIdent("");
        setLignema("");
        setDestination("");
        setDestination("");
        setTypeConteneur("");
        setDateChargement("");
        window.location.href = "/booking";
      })
      .catch((error) => {
        if (error.response) {
          //
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          setMessage(
            "Une erreur est survenue lors de la réservation. Veuillez réessayer."
          );
        } else if (error.request) {
          // La requête a été faite mais aucune réponse n'a été reçue
          console.log(error.request);
          setMessage(
            "La requête a été envoyée mais aucune réponse n'a été reçue. Veuillez vérifier votre connexion internet."
          );
        } else {
          // Quelque chose s'est passé dans la configuration de la requête qui a déclenché une erreur
          console.log("Error", error.message);
          setMessage(
            "Une erreur inattendue s'est produite. Veuillez réessayer."
          );
        }
        console.log(error.config);
      });
  };

  //suppression d'une réservation
  const handleDelete = (num_res) => {
    const confirmDelete = window.confirm(
      "Voulez vous vraiment supprimer ce Booking?"
    );
    if (confirmDelete) {
      axios
        .delete(APP_URL + `reservation/${num_res}`)
        .then((response) => {
          SetListeReservation(
            listeReservation.filter(
              (reservation) => reservation.num_res !== num_res
            )
          );
          console.log("booking supprimer avec succès");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Sidebar>
      <div className="container">
        <div className="veh">
          <form action="" className="form" onSubmit={handleSubmit}>
            <h2>Réservations</h2>

            <div className="form-group mt-4">
              <label htmlFor="lignema" className="fw-bold">
                Ligne maritime
              </label>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-check">
                    <input
                      type="radio"
                      name="lignema"
                      id="cma"
                      className="form-check-input"
                      value="CMA"
                      onChange={(e) => setLignema(e.target.value)}
                    />
                    <label htmlFor="cma" className="form-check-label">
                      CMA
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="MSC"
                      name="lignema"
                      value="MSC"
                      onChange={(e) => setLignema(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="MSC">
                      MSC
                    </label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="hapag"
                      name="lignema"
                      value="HAPAG"
                      onChange={(e) => setLignema(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="hapag">
                      Hapag
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="maersk"
                      name="lignema"
                      value="MAERSK"
                      onChange={(e) => setLignema(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="maersk">
                      MAERSK
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="destination" className="fw-bold">
                Destination
              </label>
              <input
                type="text"
                className="inputs"
                value={destination}
                name="destination"
                onChange={(e) => setDestination(e.target.value)}
              />
            </div>

            <div className="form-group mt-4">
              <label htmlFor="typeConteneur" className="fw-bold">
                Type de conteneur
              </label>
              <select
                className="inputs"
                name="type_conteneur"
                value={type_conteneur}
                onChange={(e) => setTypeConteneur(e.target.value)}
              >
                <option value="">Sélectionnez un type</option>
                <option value="40 pieds">40 pieds</option>
                <option value="20 pieds">20 pieds</option>
              </select>
            </div>

            <div className="form-group mt-4">
              <label htmlFor="datecharg" className="fw-bold">
                Date prévue de chargement
              </label>
              <input
                type="date"
                className="inputs"
                name="date_charge"
                value={date_charge}
                onChange={(e) => setDateChargement(e.target.value)}
              />
            </div>

            <button className="btn btn-success m-4">
              Ajouter votre réservation
            </button>
            {message && <p>{message}</p>}
          </form>
        </div>

        <div className="listveh table-responsive mb-5">
          <h2>Liste Bookings</h2>
          {listeReservation ? (
            <table className="table tabveh">
              <thead>
                <tr>
                  <th>Numéro booking</th>
                  <th>Ligne maritime</th>
                  <th>destination</th>
                  <th>Type conteneur</th>
                  <th>Date Chargement</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {listeReservation.map((listeReservation) => (
                  <tr key={listeReservation.num_res}>
                    <td>BK{listeReservation.num_res}</td>
                    <td>{listeReservation.lignema}</td>
                    <td>{listeReservation.destination}</td>
                    <td>{listeReservation.type_conteneur}</td>
                    <td>
                      {
                        new Date(listeReservation.date_charge)
                          .toISOString()
                          .split("T")[0]
                      }
                    </td>
                    <td>
                      <a
                        className="btn btn-edit text-success"
                        onClick={() => handleEdit(listeReservation)}
                      >
                        <i className="fa fa-edit"></i>
                      </a>
                      <a
                        className="btn btn-edit text-danger"
                        onClick={(e) => handleDelete(listeReservation.num_res)}
                      >
                        <i className="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h1>chargement de la liste des réservations</h1>
          )}
        </div>

        {/* Modal pour l'édition des réservations */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Modifier la Réservation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {editBooking && (
              <Form>
                <Form.Group>
                  <Form.Label>Ligne maritime</Form.Label>
                  <Form.Control
                    as="select"
                    value={editBooking.lignema}
                    onChange={(e) =>
                      setEditBooking({
                        ...editBooking,
                        lignema: e.target.value,
                      })
                    }
                  >
                    <option value="CMA">CMA</option>
                    <option value="MSC">MSC</option>
                    <option value="HAPAG">HAPAG</option>
                    <option value="MAERSK">MAERSK</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Destination</Form.Label>
                  <Form.Control
                    type="text"
                    value={editBooking.destination}
                    onChange={(e) =>
                      setEditBooking({
                        ...editBooking,
                        destination: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Type de conteneur</Form.Label>
                  <Form.Control
                    type="text"
                    value={editBooking.type_conteneur}
                    onChange={(e) =>
                      setEditBooking({
                        ...editBooking,
                        type_conteneur: e.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date de chargement</Form.Label>
                  <Form.Control
                    type="date"
                    value={editBooking.date_charge}
                    onChange={(e) =>
                      setEditBooking({
                        ...editBooking,
                        date_charge: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fermer
            </Button>
            <Button variant="primary" onClick={handleUpdate}>
              Enregistrer les modifications
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Sidebar>
  );
};

export default Booking;
